import React, { useState } from "react";
import { Form, Button, Card } from "react-bootstrap";
import {useParams,useNavigate} from "react-router-dom";
import * as PSOSConstants from '../../components/PSOSConstants';
import axios from "axios";


function ChangePassword() {
// initial state
const [password, setPassword] = useState("");
  const [confPassword, setConfPassword] = useState("");
  document.title = PSOSConstants.DOMAIN_NAME+"|Change Password";

// const search = useLocation().search;
// const token = new URLSearchParams(search).get('token');
// const id = new URLSearchParams(search).get('id');

const params = useParams();
const token = params.tokenId;
const id = params.userId;
const navigate = useNavigate();
const handleSubmit = (e) => {
  // prevent the form from refreshing the whole page
  e.preventDefault();

  const baseUrl =  process.env.REACT_APP_.BASE_URL ;
  // set configurations
  const configuration = {
    method: "post",
    // url: `${baseUrl}/passwordReset?token=${token}&id=${id}`,
    url: `${baseUrl}/passwordReset?token=${token}&id=${id}`,
    data: {
        password,
        confPassword
    },
  };

  // make the API call
  axios(configuration)
    .then((result) => {
      // redirect user to the auth page
      if (result.status!==200) {
        window.alert(`${result.data.msg}`);
      } else {
        window.alert(`${result.data.msg}`);
        // redirect user to the auth page
        navigate('/', { replace: true });
      }
    })
    .catch((error) => {
      window.alert(`${error.response.data.msg}`);             
    });
};

  return (
    <div>
        <div class="card-form shadow-lg col-sm-9 col-md-7 col-lg-5 mx-auto" >
        <div class="card-body ">
        <Card.Body>
            <h2>Change Password</h2>
            <hr />
            <Form onSubmit={(e) => handleSubmit(e)}>
              {/* email */}
                <Form.Group controlId="formBasicPassword">
                  <Form.Label>Password</Form.Label>
                  <Form.Control size="sm"
                    type="password"
                    name="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    placeholder="********"
                    />
                    <Form.Label>Confirm Password</Form.Label>
                  <Form.Control size="sm"
                    type="password"
                    name="confPassword"
                    value={confPassword}
                    onChange={(e) => setConfPassword(e.target.value)}
                    placeholder="********"
                  />
                </Form.Group>
            
              <br />
              {/* submit button */}
              <Button className="primary"
                variant="primary"
                type="submit"
                onClick={(e) => handleSubmit(e)}
              >
               <p>Reset password</p>
              </Button>
              
            </Form>
              </Card.Body>
            </div>
    </div>
    </div>
  )
}

export default ChangePassword;