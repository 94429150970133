import React from 'react'
import SolutionPage from '../../components/SolutionPage';


function OS() {
    const os = {
        "name": "Operating System",
        "sUrlName": "Operating-System",
        "description": "We believe that Innovations are the results of Research and Development. As being ever learners, we the PSOS Technologies R&D is currently working on Hybrid Operating System which helps you to solve all your challenges regarding OS that further ensures the Efficiency and Threat Free Operating System.",
        "metaDesc":"Hybrid Operating System across the PSOS Technologies platforms advanced OS with CHIP methodology. Apps in laptops and mobiles will work in universally by using our OS and more.",
        "catagories": [
            {
                "name": "Reasearch in Operating System",
                "description": "",
                "services": [
                    {
                        "id": 1,
                        "name": "Hybrid OS",
                        "ssUrlName": "Hybrid-OS",
                        "icon": "",
                        "Decription": "While Doing research on Hybrid OS for different platforms like MS Windows,  Ubuntu, MAC OS, Cent OS, Android and IOS using AI, we also provide different types of securities like Finger Print, Face Lock, Patter Password, Voice Recognition and Retina Recognition "
                    },
                    {
                        "id": 2,
                        "name": "How Our OS works?",
                        "ssUrlName": "How-Our-OS-works?",
                        "icon": "",
                        "Decription": "We help the apps (Mobile and Desktop Apps) to work universally using our OS. We also help you to update your Operating System in regular frequency depending on it’s type. In Precise, Normal OS with low configuration needs to be updated for every 2 years and Advanced versions with CHIP methodology (Chip set is mandatory) need a version update for every 5 years using AI."
                    },
                    {
                        "id": 2,
                        "name": "How AI is used in OS?",
                        "ssUrlName": "How-AI-is-used-in-OS?",
                        "icon": "",
                        "Decription": "Our OS Chip set can be accommodated to any System possessing 5th& 7th Generation Processors and Mobiles with 5th& 12thNanometer Technology chips. AI plays a significant role in adjusting Hybrid OS to devices and it will automatically update OS as per Chip set."
                    },
                    {
                        "id": 3,
                        "name": "Security in OS",
                        "ssUrlName": "Security-in-OS",
                        "icon": "",
                        "Decription": "PSOS Technologies Streamlines the Collection of Data for Robust Analysis of Cyber Security Incidents and Check Periodically. Detects any Security Malwares in Mobiles and Laptops when there is any breach and Provides Security Patches on regular basis (for every 3 months)."
                    }
                ]
            }
        ]

    }

    return (
        <SolutionPage solution={os} />
    )
}

export default OS