import React from 'react'
import SectorPage from '../../components/SectorPage';

function Automotive() {

    const automotive = {
        "name": "Automotive",
        "secUrlName": "Automotive",
        "description": "Innovative automotive software is changing the driving experienceAdvance softwares and ai tools have changed automotive driving Precision mapping Precision driving Cars Bus Any automotives Vehicles Future Advance autopilot systems Software to operate Memories Process sensor software Central commanding system software Automotive driving experience is changing",
        "metaDesc": "Automotive software provider software driving automotive Vehicles Future Advanced autopilot systems Software Central commanding software more.",
        "name2": "Automotive Features & Sevices",
        "icon": "FaCar",
        "Services": [
            {
                "id": 1,
                "name": "Vehicle Design",
                "ssecUrlName": "Vehicle-Design",
                "icon": "",
                "Decription": "CAD, CAM software operations are used to model the auto modules's machine corridor. Computer- backed design( CAD) design software is a combination of tackle and software that makes the designing process indefectible for the developer. It helps to integrate the mechanics and functional design of vehicles."
            },
            {
                "id": 2,
                "name": "Vehicle Safety",
                "ssecUrlName": "Vehicle-Safety",
                "icon": "",
                "Decription": "Vehicle includes safety features to stop crashes and accidents by informing the motorist and at the same time  control of the vehicle. Safety software provides adaptive features that correspond of automated lighting, automated retardation, showing blindspots, and supported reversing. Some features are handed as bedded and some areas SaaS add- on."
            },
            {
                "id": 3,
                "name": "Vehicle Navigation",
                "ssecUrlName": "Vehicle-Navigation",
                "icon": "",
                "Decription": "Vehicle Navigation software provides guidance on how to move the vehicle from one place to another through navigation GPS guidance Navigation software integrates with central command software Automated navigation software Diagram philosophy to driver commands"
            },
            {
                "id": 4,
                "name": "Vehicle Diagnostics",
                "ssecUrlName": "Vehicle-Diagnostics",
                "icon": "",
                "Decription": "Vehicle applications are of two types first part device second part computer application while driving the car diagnostic app works as a smart assistant by giving guidelines to the driver history of the vehicle gives guidance to the driver by making the route countries and gives guidance in driving how does the petrol AC conditions work in the vehicle how does the suspension work  Countries are giving"
            },
            {
                "id": 5,
                "name": "Garage Management",
                "ssecUrlName": "Garage-Management",
                "icon": "",
                "Decription": "Garage business is completely managed by garage software.  It automates the entire process from the appearance of the vehicle to its look & feel, till it is selected by the auto owner.  It becomes easy to manage and record a database of guests and vehicle and history.  Garage workloads are reduced with analytics, reporting and tab generation functions."
            },
            {
                "id": 6,
                "name": "Dealership Management",
                "ssecUrlName": "Dealership-Management",
                "icon": "",
                "Decription": "Dealership Management Software effectively manages finance, deals, factors, force, and operation mechanisms for consecutively running the dealership. Dealers do not have to flash back the duty form date as with this software it becomes robotic. We give robotization for handling creation, client relations, and deals. The advantages of this software include e-commerce interface as well as online ordering and more sales"
            },
            {
                "id": 7,
                "name": "Autonomous ADAS Solution",
                "ssecUrlName": "Autonomous-ADAS-Solution",
                "icon": "",
                "Decription": "ADAS is a better-quality way for semi-autonomous cars to understand environments and develop situational awareness for safer driving.  A system on a chip (SoC) is an integrated chip that contains autonomous application solutions or all-out components of an electronic system.  These chips are advanced core technology.  And high presentation ECUs assign sensors to actuators via .Electronic Controller Units."
            },
            {
                "id": 8,
                "name": "Auto Insurance Claim Management ",
                "ssecUrlName": "Auto-Insurance-Claim-Management",
                "icon": "",
                "Decription": "Automotive insurance claim management software workflows should be customized based on the claim being processed.  Automated repair claims process for insurers by automatically automating systems, connecting major investors, fixers and industry data, car valuation software guidelines and parts lists into one unified location.  This software features mainly providing important data for assessment of claims with unified system of record."
            },
            {
                "id": 9,
                "name": "Auto Parts Inventory Management",
                "ssecUrlName": "Auto-Parts-Inventory-Management",
                "icon": "",
                "Decription": "Inventory management involves tracking inventory for commercial. Businesses use it to track stock, reduce the problem of shortages by automatically reordering. In the mobility industry, a variety of new and old parts are used regularly to repair a vehicle, which is why automotive parts dealers often seek an automotive parts inventory management system."
            }

        ]


    }

    return (<SectorPage sector={automotive} />)
}

export default Automotive;