import React from 'react'
import SectorPage from '../../components/SectorPage';

function Food() {


    const food = {
        "name": "Food Delivery ",
        "secUrlName": "Food-Delivery",
        "description": "Food delivery is becoming more popular day by day.  Food delivery business is growing rapidly due to high usage of mobile, internet and getting things at door step. We can provide any food related software and any app to individuals or companies",
        "metaDesc": "Food Delivery We can provide any food related software and any apps to individuals or company’s delivery tracking software’s more.",
        "name2": "Food Delivery Features & Sevices ",
        "icon": "FaPeopleCarry",
        "Services": [
            {
                "id": 1,
                "name": "Grocery Delivery Apps",
                "ssecUrlName": "Grocery-Delivery-Apps",
                "icon": "",
                "Decription": "Formerly familiar in Grocery business? or Having Profound interest in setting upyour own, you actually are in the right track. With a grocery delivery app, you can't only satisfy your living guests, but you can actually reach out to a wider followership and make your presence known to people all over."
            },
            {
                "id": 2,
                "name": "Organic and Natural Food",
                "ssecUrlName": "Organic-and-Natural-Food",
                "icon": "",
                "Decription": "Healthy food is a trending Niche, getting huge attraction across all age groups. Since natural and organic foods are in-thing now, you can fluently gain attention if you vend quality produce sourced locally from organic directors. We provide apps and software which are required for how to provide organic food from organic dealers to people."
            },
            {
                "id": 3,
                "name": "Online Catering Apps",
                "ssecUrlName": "Online-Catering-Apps",
                "icon": "",
                "Decription": "Ranging from small parties and gatherings to mega events, feeding services are always in demand.As utmost people these days try to find everything online right from their mobile device, it's judicious to come. We will design mobile authorization app and websites. We provide apps for feeding services, crockeries, linen and implements, and cooking services will always gain attention."
            },
            {
                "id": 4,
                "name": "Frozen Food Ordering Apps",
                "ssecUrlName": "Frozen-Food-Ordering-Apps",
                "icon": "",
                "Decription": "The assiduity of global frozen food is anticipated to touch USD380.5 Billion by 2027. Only prognosticated grounded upon the present periodic consumption of frozen food in Asian, Middle- Eastern and European nations. There's a great occasion for any new brand to find traction. Some of the frozen food particulars include seafood, ready- to- eat refection, some veggies, potatoes, meat, dairy particulars, fruits etc."
            },
            {
                "id": 5,
                "name": "Meal Preparation App",
                "ssecUrlName": "Meal-Preparation-App",
                "icon": "",
                "Decription": "Since utmost guests like to eat foods custom prepared for their taste, you can get great traction by rolling out a custom mess medication app that allows guests to choose their own favored constituents, spices, and cooking procedures. Your app can simply allow guests to choose the constituents from the menu and admit a custom mess ready without bulging their bases."
            },
            {
                "id": 6,
                "name": "Restaurant Finding App ",
                "ssecUrlName": "Restaurant-Finding-App",
                "icon": "",
                "Decription": "AR- grounded eatery finding and evaluation app will help guests to estimate the food quality and standard of eatery service. From checking the food constituents and their newness, the AR- grounded app should help guests to estimate a eatery without really moving in. We allow guests to look at virtual menu cards and get a multimedia- supported experience of the cafes ."
            },

        ]


    }

    return (
        <SectorPage sector={food} />
    )
}

export default Food