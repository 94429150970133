import React from 'react'

function GPromoters() {
  const promoters = [
    {
      "path": "Pathi PS.webp",
      "role": "CEO"
    },
    {
      "path": "Udayagiri Ramakrishna.webp",
      "role": "CIO"
    },
    {
      "path": "Udayagiri Bhavana.webp",
      "role": "CTO"
    },
    {
      "path": "Shaik Shabbirm.webp",
      "role": "Marketing Department"
    },
    {
      "path": "JayaChandra Markapuram.webp",
      "role": "Human Resource Department"
    },
    {
      "path": "Madav Sigamala.webp",
      "role": "Operations Department"
    },
    {
      "path": "Sri Hari Sigamala.webp",
      "role": "Finance Team Leader"
    },
    {
      "path": "AG Changdra.webp",
      "role": "Research and Developement"
    },
  ]
  return (
    <div>
      <div className="nav-space">
      </div>
      <h5>Our Team Gallery</h5>
      <hr />
      <div className="cards">
        {promoters.map((promoter) => {
          return <div className="card-gallery shadow-lg col-sm-9 col-md-7 col-lg-3">
            <div class="card-body text-center">

              <img className="galleryInImage" src={`../images/employers/${promoter.path}`} width="250px" height="200px" alt={`${promoter.path}`} />
              <div className="galleryImageName"><p>{promoter.path.split(".")[0]}-{promoter.role}</p></div>

            </div>
          </div>
        })}
      </div>

    </div>
  )
}

export default GPromoters;