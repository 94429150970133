import React from 'react'
import SectorPage from '../../components/SectorPage';


function Manufacturing() {

    const manufacturing = {
        "name": "Manufacturing",
        "secUrlName": "Manufacturing",
        "description": " We can develop custom management systems for improvising your decision-making process, maximizing production, optimizing operations, and increasing revenue. Top technologies that we are utilized in building manufacturing software solutions are Location based services, Internet of Things,Al & ML,Big data and analytics, Cloud & DevOps.",
        "metaDesc": "Manufacturing software’s provide CTP software gives manufacturers. The MRP System Software directly and effectively manages all manufacturing demand more.",
        "name2": "Manufacturing Features & Sevices ",
        "icon": "FaIndustry",
        "Services": [
            {
                "id": 1,
                "name": "Activity Monitoring",
                "ssecUrlName": "Activity-Monitoring",
                "icon": "",
                "Decription": "Regularize your Manufacturing Resource Planning( MRP) updates once or several times a day or every hour.Evaluate workflow routings in document control, corrective  conduct, engineering change orders, purchase orders and  further, transferring emails or textbooks to the applicable platoon members through the software."
            },
            {
                "id": 2,
                "name": "Advanced Planning and Scheduling.",
                "ssecUrlName": "Advanced-Planning-and-Scheduling.",
                "icon": "",
                "Decription": "The flexible scheduling module executes the plans and allows real-timeadaptations to allow for unplanned events while icing to meet client demands On-time, fluently drag and drop scheduling point."
            },
            {
                "id": 3,
                "name": "Software Bill of Materials",
                "ssecUrlName": "Software-Bill-of-Materials",
                "icon": "",
                "Decription": "Manufacturing process includes work centers, labor, tools, dies, inserts, institutions, raw accoutrements, packaging, scrap rates, setup times, yield effectiveness, multipliers, supplementary outfit, etc. The graphical representation displays part figures and the  connections between packaging, fitted factors and secondary operations, tooling conditions, process notes and instructions."
            },
            {
                "id": 4,
                "name": "Capable to Promise(CTP)",
                "ssecUrlName": "Capable-to-Promise(CTP)",
                "icon": "",
                "Decription": "CTP tool is analysis of operating hours, manufacturing time conditions, machine vacuity, labor capacity, driving readinessand accoutrements needed. By combining all aspects of what's needed to manufacture aproduct. CTP software gives manufacturers better visibility into all situations and across the entire force chain. So you can respond Incontinently and directly to your guests demands."
            },
            {
                "id": 5,
                "name": "Material Resource Planning",
                "ssecUrlName": "Material-Resource-Planning",
                "icon": "",
                "Decription": "The MRP System Software directly and effectively manages all conditions, necessary to meet your manufacturing demand and maintain force levels. MRP system manage conditions for manufactured particulars andsub-assembly processes internally, it also manages conditions planning for product at alternate locales and through third-party merchandisers as well."
            },
            {
                "id": 6,
                "name": "Document Control",
                "ssecUrlName": "Document-Control",
                "icon": "",
                "Decription": "Document Control provides to produce a fully paperless terrain to break your attestation challenges. The Document Control software within Manufacturing System gives customers the capability access and manage documents, furnishing complete control and visibility. We provides a secure place for documents as well as a means for circulating the current modification, while keeping old variations on train."
            },

        ]


    }

    return (
        <SectorPage sector={manufacturing} />
    )
}

export default Manufacturing