import React from 'react'
import { Card } from "react-bootstrap";

function Promoters() {

  const promoters = [
    {
      "name": "Jayachandra Markapuram",
      "tag": "(Human Resource Department)"
    },
    {
      "name": "Ravi Chandra",
      "tag": "(Human Resource Department)"
    },
    {
      "name": "Sukanya",
      "tag": "(Human Resource Department)"
    },
    {
      "name": "Sri Hari Singanamala",
      "tag": "(Finance Team Leader)"
    },
    {
      "name": "Narsing Mamatha",
      "tag": "(Finance Department)"
    },
    {
      "name": "Venkata Keerthi",
      "tag": "(Finance Department)"
    },
    {
      "name": "Chandra obaiya Singaramala",
      "tag": "(Purchase Department.)"
    },
    {
      "name": "A G Chandra",
      "tag": "(RND research and development)"
    },
    {
      "name": "Madhav Sigamala",
      "tag": "(RND research and development)"
    },
    {
      "name": "Shaik Shabbirm",
      "tag": "(RND research and development)"
    },
    {
      "name": "Sayyed Aayesha",
      "tag": "(Operations Department)"
    },
    {
      "name": "Shaik Hirra Shajida",
      "tag": "(Operations Department)"
    },
    {
      "name": "Vallepu Balaji Kumar",
      "tag": "(Operations Department)"
    },
    {
      "name": "Pavani Kondati",
      "tag": "(Marketing Department)"
    },
    {
      "name": "Madhavi Udaygiri",
      "tag": "(Marketing Department)"
    },
    {
      "name": "Manchikalapati Malyadri",
      "tag": "(Marketing Department)"
    },
    {
      "name": "A G Shanker",
      "tag": "(Marketing Department)"
    },
  ]


  return (
    <div>
      <div class="card-get-in shadow-lg">
        <div class="card-body">
          <h5><img src={require("../../images/psos_tech_logo.jpg")} alt="psos_logo" className="about-logo" /></h5>
          <h5>Company business groups</h5>
          <Card.Body>
            <hr />
            <ul class="list-group gap-1">
              {promoters.map((promoter) => {
                return (
                  <div>
                    <li class="list-group-item d-flex justify-content-center align-items-center">
                      <p>{promoter.name} <b>{promoter.tag}</b></p>
                    </li>
                  </div>
                )
              })}
            </ul>
          </Card.Body>
          <div className='poweredBy'>
            <img src={require("../../images/psos_tech_logo.jpg")} alt="psos_logo" className="about-logo" />
            <h5>Powered By PSOS Technologies</h5>
          </div>

        </div>
      </div>
      <br />
      <br />
    </div>


  )
}

export default Promoters