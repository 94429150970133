import React from 'react'
import SolutionPage from '../../components/SolutionPage'

function CyberSecurity() {
    const cyberSecurity = {
        "name": "CyberSecurity",
        "sUrlName": "CyberSecurity",
        "description": "Innovations in PSOS Technologies provides more Advisory Modern IT that powers your Business. Our Team helps you solve the most challenging problems of your Security Teams and fix any flaws in the Software.",
        "metaDesc":"Cyber security service provided Vulnerability Management. PSOS Technologies solves challenging problems for security teams fixing any software flaws",
        "catagories": [
            {
                "name": "Cyber Security Sevices",
                "description": "",
                "services": [
                    {
                        "id": 1,
                        "name": "IT hygiene",
                        "ssUrlName": "IT-hygiene",
                        "icon": "",
                        "Decription": "We provide you our services immediately, help you detect the issue, gives remedies for malicious or compromised activities, providing unparalleled real-time visibility of Security issues across devices, Solutions and apps in your network."
                    },
                    {
                        "id": 2,
                        "name": "Vulnerability management",
                        "ssUrlName": "Vulnerability-management",
                        "icon": "",
                        "Decription": "Vulnerability Management provides you Real-Time Visibility across your Business, providing you relevant and timely information needed. This helps you reduce your exposure to attacks with zero impact on your end points."
                    },
                    {
                        "id": 3,
                        "name": "Data collection",
                        "ssUrlName": "Data-collection",
                        "icon": "",
                        "Decription": "PSOS Technologies Streamlines the Collection of Point-In-Time and Historic Forensic Data for Robust Analysis of Cyber Security Incidents and Periodic Compromise Assessments."
                    },
                ]
            }
        ]
    }

    return (
        <SolutionPage solution={cyberSecurity} />
    )
}

export default CyberSecurity