import React from 'react'
import SectorPage from '../../components/SectorPage'

function Banking() {

    const banking = {
        "name": "Banking",
        "secUrlName": "Banking",
        "description": "A Software that helps Banks to manage their clients and tomaintain a cordial relation monitoring their requirements closely. It also ameliorate the workflow inside the company and its branches, for easy investment programs, and to give the services the much needed break from overflowing requirements. We offer no detainments, miscalculations or crashes and no access to the date for third parties",
        "metaDesc": "Banking and Finance software’s apps provide Investment Banking Software. Automating everyday processes cash operation, payment processing, and credit products more.",
        "name2": "Banking Features & Sevices",
        "icon": "FaLandmark",
        "Services": [
            {
                "id": 1,
                "name": "Core Banking System",
                "ssecUrlName": "Core-Banking-System",
                "icon": "",
                "Decription":"CBS is a general collection of accounts that handles main operations. These processes include Account Operation, Payment Processing, Deposits, Recessions, Compliance and so on. Core Banking results integrate with other modules. They also act as Core Databases from which Information flows to Other Corridor of the Bank. "
            },
            {
                "id": 2,
                "name": "Corporate Banking",
                "ssecUrlName": "Corporate-Banking",
                "icon": "",
                "Decription": "“Corporate banking” isn't the same as Furnishing Banking Services. It refers to Products and Services Offered and this point of time we are offering them to VeritablyLarge Traded Companies.Corporate Banking Brigades give Fiscal Services like Cash Operation, Payments Processing, Credit Products and Secure Strategies."
            },
            {
                "id": 3,
                "name": "Mortgage",
                "ssecUrlName": "Mortgage",
                "icon": "",
                "Decription": "We plan to provide a vide variety of features like Mortgage Calculators including FHA, Conventional Jumbo, Pastoral Casing, VAand Affordability. Now, Borrowers can thoroughly explore Mortgage Scripts to Match their Budget from their Mobile App or Web Dashboard. Respective Team will be notified every time when a computation is done, furnishing visibility into options for borrowers"
            },
            {
                "id": 4,
                "name": "Investment Banking Software ",
                "ssecUrlName": "Investment-Banking-Software",
                "icon": "",
                "Decription": "Close watch on Investment Banking is very much into consideration. It needs large number of software results for automating everyday processes. Utmost Banks give complex deals and deal with huge enterprises where there is a need to reuse big blocks of data in no time. The lower mortal work is needed, the further profit an investment bank can gain."
            },
            {
                "id": 5,
                "name": "Digital Signatures",
                "ssecUrlName": "Digital-Signatures",
                "icon": "",
                "Decription": "Online Leasing provides all the Convenience of Electronic Leasing with all the Integrity of a Traditional Parcel. In fact, Electronic autographs are Indeed more secure than Physical ones.Digital Signatures have been held up as equal to any Paper Document. More yet, Electronic Signatures give an Inspection Trail to ensure their Validity."
            },
            {
                "id": 6,
                "name": "Risk Management",
                "ssecUrlName": "Risk-Management",
                "icon": "",
                "Decription": "The threat of either Accidental/Intentional caused by Internal Processes, People, External Eventsor Systems impacts hugely onBank’s Business.Several Banks fail to Understand, Measure and Manage Functional threatincluding Executive Processes, IT Systems and Mortal geste. However, we provide Threat Identification, Analysis and Nonstop Monitoring and Enhancement."
            },

        ]


    }

    return (
        <SectorPage sector={banking} />
    )
}

export default Banking