import React from 'react';
import { Card } from "react-bootstrap";

function Ethics() {
  return (
    <div>
           
    <div class="card-get-in shadow-lg">
        <div class="card-body">
          <h5><img src={require("../../images/psos_tech_logo.jpg")} alt="psos_logo" className="about-logo"/></h5>
          <h5>Our Company Ethics</h5>
          {/* <h5><img  src={`../images/About/Ethics.PNG`} width="150" height="100" alt="" /></h5> */}
          <hr />
          <Card.Text style={{ color: "#1c0373" }}>
          Our Company Dreams to do business
          with ethics and honesty in every
          business. We always want people to be
          happy and we do business. Our dream is
          to grow and grow people. Let's wait for
          50% of our company's revenue to charity,
          PSOS Technologies charitable trust. PSOS Technologies trust only in one
          way. When you give good, you get
          good.PSOS Technologies they have held positions in
          trust, not positions of power.
          </Card.Text>
          <div className='poweredBy'>
            <img src={require("../../images/psos_tech_logo.jpg")} alt="psos_logo" className="about-logo" />
            <h5>Powered By PSOS Technologies</h5>
          </div>
       </div>
    </div>
    <br />
    <br />
</div>
  )
}

export default Ethics