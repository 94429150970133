import React from 'react'

import SectorPage from '../../components/SectorPage';
function Agriculture() {
    
    const agriculture = {
        "name": "Agriculture",
        "secUrlName": "Agriculture",
        "description": "We partner to improve your decision-making process, leverage the best technologies we use to develop adaptive farm management systems for increasing agricultural production, optimizing operations and increasing revenue streams.  Building Agriculture Software and Apps Solutions: Location-Based Services Internet of Things, AL & ML, Big Data and Analytics, Cloud & DevOps",
        "metaDesc": "Agriculture Software and Apps Solutions provide farm management Fertilization Irrigation Crop rotation planting harvest Track farming Location animal management.",
        "name2": "Agriculture Softwares and Apps",
        "icon": "FaSeedling",
        "Services": [
            {
                "id": 1,
                "name": "Agriculture farm management",
                "ssecUrlName": "Agriculture-farm-management",
                "icon": "",
                "Decription": "Ranch  operation software  results can help you in crop planning, reporting,    force, account, and  outfit  conservation."
            },
            {
                "id": 2,
                "name": "Aquaculture",
                "ssecUrlName": "Aquaculture",
                "icon": "",
                "Decription": "We can  fluently track the population of your harvesting and  parentage. This includes ERP integrations and monoculture workflow, detector technology, data analysis, fish  ranch  operation systems, and more."
            },
            {
                "id": 3,
                "name": "Livestock management",
                "ssecUrlName": "Livestock-management",
                "icon": "",
                "Decription": "We can develop agriculture consulting software solution offers advanced suggestions and analytics for breeding, herd management, and finance management."
            },
            {
                "id": 4,
                "name": "Agriculture Softwares and Apps",
                "ssecUrlName": "Agriculture-softwares-and-apps",
                "icon": "",
                "Decription": "All types of software apps in agriculture, small companies, big companies, persons, software names .Pest control .Fertilization .Irrigation .Equipment maintenance .Crop rotation .Planting/harvest .Track farming  .Location technology (animal management)  .Weather .Disease .Geolocation  .Risk management .Loss prevention  .Food safety protocols. We can provide these softwares."
            }

        ]


    }

    return (
        <SectorPage sector={agriculture} />
    )
}

export default Agriculture