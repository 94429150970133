import React from 'react'
import Card from "react-bootstrap/Card";


function Vision() {
  return (
    <div>
      <div class="card-get-in shadow-lg">
        <div class="card-body">
          <h5><img src={require("../../images/psos_tech_logo.jpg")} alt="psos_logo" className="about-logo" /></h5>
          <h5>Our Company Vision</h5>
          <hr />
          <Card.Text style={{ color: "#1c0373" }}>
          Our company's Vision is to expand in all sectors, for example
                  from <b>salt to pulses to mercury.</b>Our dream is to have our{" "}
                  <b>DNA in every business.</b>
          </Card.Text>
          <div className='poweredBy'>
            <img src={require("../../images/psos_tech_logo.jpg")} alt="psos_logo" className="about-logo" />
            <h5>Powered By PSOS Technologies</h5>
          </div>
          <br />
        </div>
      </div>
      <br />
      <br />

    </div>
  )
}

export default Vision