import React from 'react'
import SectorPage from '../../components/SectorPage';


function RealEstate() {

    const realEstate = {
        "name": "Real Estate & Property",
        "secUrlName": "Real-Estate-&-Property",
        "description": "We understand the role of every player in Real estate Business as theydeals with numerous people and work on large- scale systems. It needs to be a close focus on threat and cost operation, which are pivotal in this sector. Hencethey need assiduity-specific software. We provide that exact software which includes Planning, Scheduling, Budgeting, Communicating, Assigning and Covering tasks.Similarly they can avoid expensive detainments and focus on important deadlines.",
        "metaDesc": "Real Estate and Property software’s provide customized specific software. We give software that includes of planning scheduling budgeting communicating.",
        "name2": "Real Estate Features & Sevices",
        "icon": "FaCity",
        "Services": [
            {
                "id": 1,
                "name": "Real Estate Management",
                "ssecUrlName": "Real-Estate-Management",
                "icon": "",
                "Decription": "This software makes Real Estate Operations easy and accessible by Streamlining Communication between Stakeholders, Managing and Cataloging everyday tasks, Tracking Rosters and Prospective  guests as well as Risk Operations."
            },
            {
                "id": 2,
                "name": "Customer Relationship Management",
                "ssecUrlName": "Customer-Relationship-Management",
                "icon": "",
                "Decription": "Retaining a Client retained is equally important as attracting a New Client. Our CRM helps you in keeping all your guests in a Close Knit. This results in Referrals which leads toBusiness Growthand leveraging Profit and Sustainability in the market for any Real Estate business."
            },
            {
                "id": 3,
                "name": "Reports Management",
                "ssecUrlName": "Reports-Management",
                "icon": "",
                "Decription": "Reports management provides all kinds data wrt Real Estate Practices, Number of Sales, Revenue Generated, Commission per Sale, Project Reports, Clients Documents, Advance Booking details and Receipt & Payment Management."
            },

        ]


    }

    return (
        <SectorPage sector={realEstate} />
    )
}

export default RealEstate