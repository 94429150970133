import React from 'react';

// import Soultions from './SSolutions/Solutions';
// import Soultions from './SSolutions/Solutions copy 2';
import Soultions from './SSolutions/Solutions copy';
import Sectors from "./Sectors copy";
import LatestNews from '../components/LatestNews';
import * as PSOSConstants from '../components/PSOSConstants';


function Home() {
  document.title = PSOSConstants.DOMAIN_NAME;
  return (
    <div>
      <div className="mt-5">
        <Soultions />
        <LatestNews/>
        <Sectors />
      </div>
    </div>
  )
}

export default Home