import React from 'react'
import SolutionPage from '../../components/SolutionPage'


function MobileApps() {


    const mobileApps = {
        "name": "Mobile Apps",
        "sUrlName": "Mobile-Apps",
        "description": "We being into multiple services have separate teams for iOS, Android and Cross-Platform App Development and Designing for different OS and Platforms.",
        "metaDesc":"Mobile apps and Services, provide IOS App Development. Android App Development. Cross Platform App using Swift, Kotlin, Flutter and React Native respectively.",
        "catagories": [
            {
                "name": "Our Sevices in mobile apps",
                "description": "",
                "services": [
                    {
                        "id": 1,
                        "name": "iOS App Development",
                        "ssUrlName": "iOS-App-Development",
                        "icon": "",
                        "Decription": "We have a dedicated team to design and develop native iOS Apps for all your iOS gadgets like iPhone, iPad, Apple Watches, Apple TV's, iPad, MAC etc"
                    },
                    {
                        "id": 2,
                        "name": "Android App Development",
                        "ssUrlName": "Android-App-Development",
                        "icon": "",
                        "Decription": "We also have e team who works on Android Apps for Mobiles, Android TV, Android Watches etc. We design all types of apps including advance Android applications for customer. We are specialized in designing and delivering all kind of apps that are compatible with Android for customers."
                    },
                    {
                        "id": 3,
                        "name": "Cross Platform App",
                        "ssUrlName": "Cross-Platform-App",
                        "icon": "",
                        "Decription": "We have a team for cross-platform app development.Designing and Developing Cross-Platform Applications for Mobile, Tablets and Desktop Applications are the functionalities of this team. We also aim for Delivering them withHigh-Quality as Native Applications and ensure that they are Operated on Multiple Operating Systems."
                    }
                ]
            },
            {
                "name": "We use most popular tech stack as per the Trend",
                "description": "",
                "services": [
                    {
                        "id": 1,
                        "name": "Swift",
                        "ssUrlName": "Swift",
                        "icon": "",
                        "Decription": "Swift is a one of the Powerful Security Programming Language for the OS of iOS, iPad, MAC, TV and Watch. It is not only Powerful but alsoEasy to Use and Highly Secure. As the apps developed in this language works at lightning fast,we use this language the most."
                    },
                    {
                        "id": 2,
                        "name": "Kotlin",
                        "ssUrlName": "Kotlin",
                        "icon": "",
                        "Decription": "Kotlin is another Programming Language which is being used by 60/100 percent ofProfessionals especially the Android Developers. It is highly preferred by them as it makes coding brief but comprehensive and fun. We also ensure for its high Security."
                    },
                    {
                        "id": 3,
                        "name": "Flutter",
                        "ssUrlName": "Flutter",
                        "icon": "",
                        "Decription": "Flutter is an Open-Source UI Software Development Kit and Tool, developed by Google that can be used in all kinds of apps and software through single coding. For instance, Android, iOS, Linux, MAC OS, Windows, Google Fuchsia use this and design cross-platform applications."
                    },
                    {
                        "id": 3,
                        "name": "React Native",
                        "ssUrlName": "React-Native",
                        "icon": "",
                        "Decription": "React Native allows you to create apps, software and websites. Itis easily modifiable, design any UI to suit your user experience. It provides simple text editing of images native to the platform and a core set of single components"
                    }
                ]
            }
        ]
    }

    return (
        <SolutionPage solution={mobileApps} />
    )
}

export default MobileApps