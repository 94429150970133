import React from 'react'
import { Card } from "react-bootstrap";
import { BsShieldLockFill, BsFillCloudArrowUpFill, BsLaptopFill, BsCpuFill, BsGlobe2, BsPhoneFill, BsBroadcast, BsTerminalFill } from "react-icons/bs";
import { FaBrain } from "react-icons/fa";
import { Link } from "react-router-dom";
import { IconContext } from "react-icons";

import store from '../../store';
import { useSelector } from "react-redux";

function Solutions() {
    const { auth } = store.getState();
    const unsubscribe = store.subscribe;

    const userName = useSelector(state => state.auth.user.name);

    const solutions = [
        {
            "id": 1,
            "name": "Research on new OS",
            "icon": <BsTerminalFill />,
            "url": "os"
        },
        {
            "id": 2,
            "name": "Cyber- Security",
            "icon": <BsShieldLockFill />,
            "url": "cyber-security"
        },
        {
            "id": 3,
            "name": "Cloud computing",
            "icon": <BsFillCloudArrowUpFill />,
            "url": "cloud"
        },
        {
            "id": 4,
            "name": "Mobile Apps",
            "icon": <BsPhoneFill />,
            "url": "mobile"
        },
        {
            "id": 5,
            "name": "Web Apps",
            "icon": <BsGlobe2 />,
            "url": "web"

        },
        {
            "id": 6,
            "name": "Desktop Apps",
            "icon": <BsLaptopFill />,
            "url": "desktop"

        },
        {
            "id": 7,
            "name": "AI & Machine learning",
            "icon": <FaBrain />,
            "url": "ai"

        },
        {
            "id": 8,
            "name": "Internet of Things",
            "icon": <BsBroadcast />,
            "url": "iot"

        },
        {
            "id": 9,
            "name": "Embedded Software",
            "icon": <BsCpuFill />,
            "url": "embedded"
        },
    ]

    return (
        <div >
            <h1 className='whiteheading'>WELCOME TO PSOS TECHNOLOGIES</h1>
            <hr />
            <div className="cards row">
                {solutions.map((solution) => {
                    return (<div className="card solicon shadow-lg col-3 col-sm-3 col-lg-3" key={solution.id}>
                        <div class="sol-card-body">
                            <Link to={`solutions/${solution.url}`} style={{ color: 'inherit', textDecoration: 'inherit' }}>
                                <h2 className="icons-h5">{solution.name}</h2>
                                <Card.Body className="icons-card-body">
                                    <IconContext.Provider value={{ color: 'rgb(8, 8, 114)', size: '3rem' }}>
                                        {solution.icon}
                                    </IconContext.Provider>
                                </Card.Body>
                            </Link>
                        </div>
                    </div>)
                })}
            </div>
        </div>

    )
}

export default Solutions