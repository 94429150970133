import React from 'react'
import SectorPage from '../../components/SectorPage';

function Housing() {

    const housing = {
        "name": "Housing & Construction",
        "secUrlName": "Housing-&-Construction",
        "metaDesc": "Housing and Construction Software Apps are provided construction 3D designs, Lead and Bid Management, CAD design, Purchase Management, Tolerance Evaluation.",
        "description": "Housing & Construction Software are Completely Integrated with Structured Lifecycle and Quality Assurance(QA) and Quality Control(QC).Operation tools empowering construction professionals to continuously estimate systems with real time comparisons against CAD designs using 3D checkup data.",
        "name2": "Housing & Construction",
        "icon": "FaBuilding",
        "Services": [
            {
                "id": 1,
                "name": "Construction Management",
                "ssecUrlName": "Construction-Management",
                "icon": "",
                "Decription": "Construction Management, Monitor Subcontractors, Raise customer Bills and Seller Bills, Examining Yearly Job- Cost Reports are the benefits we offer. Provides all systems income vs expenditure and shows Original Estimation, Revised Estimation, Actuals Incurred, Balance, Variance and Projected figures with weighted  adaptations for Factual volume."
            },
            {
                "id": 2,
                "name": "Lead and Bid Management",
                "ssecUrlName": "Lead-and-Bid-Management",
                "icon": "",
                "Decription": "Capture and Manage Construction  Design Enquiries,  shot  cessions up to tender award in Tendering. Track process like tender bills,  point  examinations, rate enquiries, tender workings, tender document  cessions, and accommodations  fluently. It facilitates  literal tender analysis to edge pricing and  periphery  computation  icing strategic  concession with  guests, sub-contractors and  merchandisers. "
            },
            {
                "id": 3,
                "name": "3D Scan to Validate",
                "ssecUrlName": "3D-Scan-to-Validate",
                "icon": "",
                "Decription": "Guarantee that structures are being constructed to design specifications. Collaborateddelicacy to checkup data and to design models, descry incorrect placement or missing features, similar as wall columns, shafts, pipesetc.Utilizing complete digital 3D reviews, snappily and fluently perform construction QA/ QC analysis, reducing prolonged hours of homemade  confirmation to bare  twinkles."
            },
            {
                "id": 4,
                "name": "Tolerance Evaluation",
                "ssecUrlName": "Tolerance-Evaluation",
                "icon": "",
                "Decription": "Save and accelerate the design schedule while reducing scrap, time and  coffers. Perform important  measures directly and snappily. Check construction for adherence to  StructureNorms(Bottom Flatness/ Bottom Status, Beam Camber, Cut & Fill Volume, Wall Plumbness, Tank Deformation and Volume Analysis)."
            },
            {
                "id": 5,
                "name": "Inventory Management",
                "ssecUrlName": "Inventory-Management",
                "icon": "",
                "Decription": "Material Transfer out & in operations along with respective Tax Invoices. Manage entire material purchase and stores functions of a construction company.Perform inter-site material transfers. Do quality checks on material arrivals. Do emergency cash purchases as site level. Issue material to subcontractors."
            },
            {
                "id": 6,
                "name": "Purchase Management",
                "ssecUrlName": "Purchase-Management",
                "icon": "",
                "Decription": "We develop Purchase Management, CentralizedCollective Operations, Purchase Requests and Approvals. Dashboard to centrally manage all material, equipment or machinery purchase invitation for bid (IFB) to vendors using Vendor Portal and purchase based on vendor payables, taxes and related legal compliances."
            },

        ]


    }

    return (
        <SectorPage sector={housing} />
    )
}

export default Housing