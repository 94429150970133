import React from 'react'
import SectorPage from '../../components/SectorPage';

function Insurance() {

    const insurance = {
        "name": "Insurance",
        "secUrlName": "Insurance",
        "description": "Insurance software refers to specialized applications and platforms designed to support the operations of insurance companies. These software solutions typically cover various aspects of the insurance business, including policy administration, claims management, underwriting, billing, and customer relationship management (CRM). Here are some key types and features of insurance software providing",
        "metaDesc": "Insurance software and apps services provide Policy Operation software to produce and manage insurance programs helps insurers to claim workflow more.",
        "name2": "Insurance Features & Sevices",
        "icon": "FaChild",
        "Services": [
            {
                "id": 1,
                "name": "Document-Management",
                "ssecUrlName": "Document-Management",
                "icon": "",
                "Decription": "We provide muchneeded software the companies can rely on for their daily operations with Documents Storage and Organize them such asPolicies, ClientAttestations, Contracts, Policyholder Agreementsetc."
            },
            {
                "id": 2,
                "name": "Insurance workflow automation",
                "ssecUrlName": "Insurance-workflow-automation",
                "icon": "",
                "Decription": "Insurance processes are usually complex and will have multiple validations. We provide workflow automation facility in your sales, call-center, and applications to improve process efficiency and team productivity."
            },
            {
                "id": 3,
                "name": "Policy Management",
                "ssecUrlName": "Policy-Management",
                "icon": "",
                "Decription": "Policy Operation Software that helps Produce, Administer, and Manage Insurance Programs. Some cases need facultative arrangements and Reinsurance. We reduce the assuring pitfalls and handle reinsurance processes efficiently."
            },
            {
                "id": 4,
                "name": "Approval Management",
                "ssecUrlName": "Approval-Management",
                "icon": "",
                "Decription": "Approvals are the most crucial aspect in insurance business as it evaluates the company's risk in insuring a home, car, or an individual's health or life. We provide users to write rules and policies to execute automatically saving manual efforts and reducing human errors."
            },
            {
                "id": 5,
                "name": "Claims-Management",
                "ssecUrlName": "Claims-Management",
                "icon": "",
                "Decription": "Software helps insurers to streamline claim operation workflow,  induce claim documents automatically, manage payments, check fraudulent claims and integrate policy administration. We help insurers reduce claim operation costs, reduce fraudulent claims and enhance  client experience"
            },
            {
                "id": 6,
                "name": "E KYC Solutions",
                "ssecUrlName": "E-KYC-Solutions",
                "icon": "",
                "Decription": "Insurance non-supervisory authorities in some countries have allowed  videotapegrounded KYC(know your client) for onboarding guests. It supports videotape recording and screenshot, real- time verification, landing geolocation, and picture verification(matching  client snap with ID snap)."
            },

        ]


    }
    return (
        <SectorPage sector={insurance} />
    )

}

export default Insurance