import React from 'react'
import { useParams, Outlet } from "react-router-dom";
import { Card } from "react-bootstrap";

function Solution() {
    let { solutionId } = useParams();
    const solutions = [
        {
            "id": 1,
            "name": "Research on OS development",
            "description": "Research on OS development description",
            "nested": [
            ]
        },
        {
            "id": 2,
            "name": "Cyber Security",
            "description": "Test description Cyber Security",
            "nested": [
            ]
        },
        {
            "id": 2,
            "name": "Cloud Computing",
            "description": "Cloud computing is the delivery of computing services including servers, storage, databases, networking, software, analytics, and intelligence over the Internet to offer faster innovation, flexible resources, and economies of scale.",
            "nested": [
                {
                    "id": 1,
                    "name": "Infrastructure as a Service (IaaS)",
                    "icon":"",
                    "Decription": "Infrastructure as a Service (IaaS) refers to the fundamental building blocks of computing that can be rented: physical or virtual servers, storage and networking."
                },
                {
                    "id": 2,
                    "name": "Platform as a Service (PaaS)",
                    "icon":"",
                    "Decription": "Platform as a Service (PaaS) is the next layer up – as well as the underlying storage, networking, and virtual servers, this layer also includes the tools and software that developers need to build applications on top, which could include middleware, database management, operating systems, and development tools."
                },
                {
                    "id": 3,
                    "name": "Software as a Service (SaaS)",
                    "icon":"",
                    "Decription": "Software as a Service (SaaS) is the delivery of applications as a service, probably the version of cloud computing that most people are used to on a day-to-day basis. The underlying hardware and operating system is irrelevant to the end user, who will access the service via a web browser or app;"
                },
            ]
                
        },
    ]
    
    const solution = solutions[solutionId-1];

  return (
    <div>
          <Outlet />
          <h5>{solution.name}</h5>
          <hr />
          <h6>{ solution.description}</h6>
    <div className="cards">
    {solution.nested.map((eachSolution, id) => {
        return (<div className="card shadow-lg m-3 p-2 col-sm-9 col-md-7 col-lg-3 ">
            <div class="card-body">
                <h5>{eachSolution.name}</h5>
                <hr />
                <Card.Body>
                    {eachSolution.Decription}
                </Card.Body>
            </div>
        </div>)
    })}
    </div>
 </div>
  )
}

export default Solution