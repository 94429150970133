import { createStore, applyMiddleware, compose } from "redux";
import combineReducers from "./reducer/index";

const initialState = {};
// const middleware = [thunk];
const store = createStore(
  combineReducers,
  initialState,
  compose(
    applyMiddleware(),
    // window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
  )
);
export default store;