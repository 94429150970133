import React from 'react'
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import PageHead from "./PageHead";
import * as PSOSConstants from '../components/PSOSConstants';

function SolutionPage(props) {
    const { solution } = props;
    const pagetitle = PSOSConstants.DOMAIN_NAME+"|" + solution.name;
    // const solutionName = solution.name.replaceAll(" ", "-");
    
    return (<div className="solution-page" >
        <PageHead title={pagetitle} description={solution.metaDesc} />
        <h1>{solution.name}</h1>
        <hr />
        <p>{solution.description}</p>
        <hr />
        <div>
            {solution.catagories.map((catagory) => {
                return (<div>
                    <h2>{catagory.name}</h2>
                    <hr />
                    <p>{catagory.description}</p>

                    <div className="cards">
                        {
                            catagory.services.map((service) => {
                                // const subSolutionName = service.name.replaceAll(" ", "-");
                                return (<div className="card-sector shadow-lg col-sm-9 col-lg-3" key={service.id}>
                                    <div class="card-body">
                                        <h2>{service.name}</h2>
                                        <hr />

                                        <p>{service.Decription}</p>
                                        <br />
                                        <br />
                                        <Link to={`/getintouchSol/${solution.sUrlName}/${service.ssUrlName}`}>
                                            <Button type="submit" className="more-sector"><p>Connect With Us</p></Button>
                                        </Link>
                                    </div>
                                </div>
                                )
                            })
                        }
                    </div>
                    <hr />
                </div>)
            })

            }
        </div>

    </div>
    )
}

export default SolutionPage;