import React from 'react'
import { Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import PageHead from "../components/PageHead";
import * as PSOSConstants from '../components/PSOSConstants';

function Gallery() {

  const gallery_images = [
    {
      "path": "psos latest format 3.jpg",
      "title": "Our Team",
      "description": "Our team collaborative effective and efficient way to achieve our goal.",
      "url": "team"
    }
  ]

  const pagetitle = PSOSConstants.DOMAIN_NAME+"|Gallery";
  const metaDesc = "PSOS Technologies company gallery. Company higher official pics and their designation details and event gallery details.";

  return (
    <div className="gallery-page">
      <PageHead title={pagetitle} description={metaDesc} />
      <h5>Gallery</h5>
      <hr />
      <div className="cards">
        {gallery_images.map((image) => {
          return <div className="card-gallery shadow-lg col-sm-9 col-md-7 col-lg-3">
            <div class="card-body">
              <Link to={`/gallery/${image.url}`}>
                <Card.Body>
                  <img className="galleryImage" src={`../images/Gallery/${image.path}`} width="250" height="200" alt="" />
                </Card.Body>
                <h5>{image.title}</h5>
              </Link>
              <p>{image.description}</p>
            </div>
          </div>
        })}
      </div>

    </div>
  )
}

export default Gallery