import React from 'react'
import SectorPage from '../../components/SectorPage';


function Marine() {


    const marine = {
        "name": "Marine",
        "secUrlName": "Marine",
        "description": "Billions of dollars worth of food items are transported by waterways every day.  A million containers move from one place to another through waterways worldwide every day.  The whole world is shifting towards advanced technology and marine industry is also modernizing towards smart shift technology. We provide ships with the software they need. Be it Autonomous Technology, Internet of Things (IOT) and Data Analytics, that are working together to provide software which is suitable for Advanced Shipping Intelligence",
        "metaDesc": "Marine software and apps provide smart shift technology. We provide ships with the software they need in autonomous technology central command software.",
        "name2": "Marine Features & Sevices",
        "icon": "FaWater",
        "Services": [
            {
                "id": 1,
                "name": "Smart Propulsion Systems",
                "ssecUrlName": "Smart-Propulsion-Systems",
                "icon": "",
                "Decription": "As the demand for ship profile system increases, advanced smart technology provides guidance to the ship's captain. The ship gives the captain a greater degree of control. Smart Technology Profile systems are designed to be controlled by software. An engine's performance profile represents system performance to computer engineers. The central command software automatically alerts the captain when any problems occurs in the ship."
            },
            {
                "id": 2,
                "name": "IoT on Vessels",
                "ssecUrlName": "IoT-on-Vessels",
                "icon": "",
                "Decription": "The Internet of effects( IoT) enables druggies to control everyday objectives with the help of their phone or a consolidated control system. This means people can turn electrical systems on and off, doors and bulkhead systems have to be nearly covered during harborage calls, lading and disburdening operations by allowing a single existent to cover and control the systems, for more important tasks."
            },
            {
                "id": 3,
                "name": "Digital Route Management",
                "ssecUrlName": "Digital-Route-Management",
                "icon": "",
                "Decription": "Real-time route operation plays a major role in perfecting trip duration and effectiveness.  As ocean conditions can occasionally change drastically over a period, it is important to have real-time data available for Helmsman.  We can provide apps for rainfall patterns, pirating precautions, harborage business, software to manage routes, exact route and various other parameters."
            },
            {
                "id": 4,
                "name": "Marine Market Tools",
                "ssecUrlName": "Marine-Market-Tools",
                "icon": "",
                "Decription": "Automate email processing and combine the extracted data on container positions and cargo enquiries with AIS, market rates and so much more to get one place where you follow the markets.Estimate container traffic around major ports and port expenses using our Ports Dashboard."
            },

            {
                "id": 5,
                "name": "Marine Management",
                "ssecUrlName": "Marine-Management",
                "icon": "",
                "Decription": "Admin dashboard to manage all your vessels individual accounts.Manage all documents, licenses and instruments for vessel, crew, outfit and further. Furthermore allowing crew to produce leave, track leave, holiday."
            },

        ]


    }
    return (
        <SectorPage sector={marine} />
    )

}

export default Marine