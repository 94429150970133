import React from 'react'
import { Card, Button } from "react-bootstrap";
import Form from 'react-bootstrap/Form';
import { useState } from "react";
import axios from "axios";
import { useParams } from 'react-router-dom';
import PageHead from "./PageHead";
import * as PSOSConstants from '../components/PSOSConstants';

function GetInTouchSolutions() {

  const { solution, subSolution } = useParams();
  const name = solution;
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [description, setDescription] = useState("");

  const pagetitle = PSOSConstants.DOMAIN_NAME+"|Get in Touch";
  const metaDesc = "Get InTouch with PSOS Technologies team. For any General query or any Specific Software Services, projects and consultation. Please provide your requirements and details. Our specific team will contact you.";

  const handleSubmit = (e) => {
    // prevent the form from refreshing the whole page
    e.preventDefault();
    const baseUrl = process.env.REACT_APP_.BASE_URL;
    // set configurations
    const configuration = {
      method: "post",
      url: `${baseUrl}/getInTouch`,
      data: {
        email,
        phoneNumber,
        description,
        name
      },
    };

    // make the API call
    axios(configuration)
      .then((result) => {
        // redirect user to the auth page
        window.alert(`${result.data.msg}`);
        window.location.href = "/";
      })
      .catch((error) => {
        console.log(error);
        window.alert(`${error.data.msg}`);
      });
  };



  return (
    <div class="card-get-in shadow-lg col-sm-10 col-md-7 col-lg-7 mx-auto" >
      <PageHead title={pagetitle} description={metaDesc} />
      <div class="card-body ">
        <Card.Body>
          <h5><img src={require('../images/psos_tech_logo.jpg')} width="70" height="50" alt="" /></h5>
          <h2>Connect With Us</h2>
          <hr />
          <h2><b>{solution.replace(/-/g," ")}</b></h2>
          <p>For any <span className='subservice-name'>{subSolution.replace(/-/g," ")} Software Services, projects and consultation.</span> Please provide your requirements and details.Such that our specific team will contact you soon..!.</p>
          <hr />
          {/* <p>Please provide basic details to contact you </p> */}
          <Form>
            <Form.Group controlId="formBasicEmail">
              <Form.Label>Email address</Form.Label>
              <Form.Control size="sm" type="email"
                name="email" value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="example@gmail.com" />
              <Form.Text className="text-muted">
                <p>We'll never share your email with anyone else.</p>
              </Form.Text>
            </Form.Group>

            <Form.Group controlId="formPhoneNumber">
              <Form.Label>Phone Number</Form.Label>
              <Form.Control size="sm" type="text"
                name="phoneNumber" value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
                placeholder="+91 91000 00000" />
            </Form.Group>
            <Form.Group controlId="formDescription">
              <Form.Label>Please provide clear description</Form.Label>
              <Form.Control size="sm" as="textarea" aria-label="With textarea"
                name="description" value={description}
                onChange={(e) => setDescription(e.target.value)}
                placeholder="Please provide clear description of what you need from our side.Such that out specific team will reach you soon..!" />
            </Form.Group>
            <br />
            <Button className="primary" type="submit"
              onClick={(e) => handleSubmit(e)}>
              <p>Submit</p>
            </Button>
          </Form>
        </Card.Body>
      </div>
    </div>
  );
}

export default GetInTouchSolutions;
