import React from 'react'
import SolutionPage from '../../components/SolutionPage';

function DesktopApp() {
    const desktop = {
        "name": "Desktop apps",
        "sUrlName": "Desktop-apps",
        "description": "Desktop apps are the software programs which interacts with Operating System instead of Web-based or Mobile Apps. To quote examples of desktop apps, Photoshop, Skype, Browsers like Chrome, IE, and Firefox, Discord, Microsoft File Explorer, Microsoft Word, Excel etc. are few. Why Desktop Apps?  Increased Profitability in your Market Return on Investment.   Much Lower Costs to develop.  No Maintenance Cloud like Hosting, Domain, Security.  No Internet Cost.",
        "metaDesc":"Desktop apps & services provide Windows desktop apps. Macros apps provide We develop cross platform solutions for organizations product companies or individuals",
        "catagories": [
            {
                "name": "Different types of Desktop sevices ",
                "description": "",
                "services": [
                    {
                        "id": 1,
                        "name": "Microsoft Windows",
                        "ssUrlName": "Microsoft-Windows",
                        "icon": "",
                        "Decription": "We design applications and apps based on Windows Operating System as it is used by majority of the people all over world. How to build Windows Desktop Apps using Advanced Tools andTechnologies?We have a special team who helps you build the application on Windows Operating System as per your requirement."
                    },
                    {
                        "id": 2,
                        "name": "macOS",
                        "ssUrlName": "macOS",
                        "icon": "",
                        "Decription": "Mac OS is usually used in Apple laptops and desktops. We are also into making applications that are suitable for Mac OS. We not only make the applications but also do the User Interface attractive using advanced version.We also work on Software shortcuts alongside designing apps and fixing any kind of errors in web applications. Special apps are designed and modified as per Users requirement."
                    },
                    {
                        "id": 3,
                        "name": "Enterprise integration",
                        "ssUrlName": "Enterprise-integration",
                        "icon": "",
                        "Decription": "We develop Cross-Platform Solutions for Organizations, Product based Companies or Individuals that approach us. We provide our Customers the Best Services across  Multiple Technologies and Variabilities in a Single Solution. Our team always works to meet the needs of our customers."
                    }
                ]
            },
            {
                "name": "We use most popular tech stack as per the Trend",
                "description": "",
                "services": [
                    {
                        "id": 1,
                        "name": "C#",
                        "ssUrlName": "C#",
                        "icon": "",
                        "Decription": "C# is provided by Microsoft Corporation. It is similar to JAVA and JavaScript programming but with a difference. Microsoft builds C#, Windows developers build Windows applications and desktop apps. Additionally, C# is useful for designing secure apps that work in modern apps the .NET ecosystem,Environment."
                    },
                    {
                        "id": 2,
                        "name": "C++",
                        "ssUrlName": "C++",
                        "icon": "",
                        "Decription": "C++ is a general-purpose procedural programming language. This language is used to build Operating Systems as well and System Applications with advanced security. C++ is used to develop applications like Desktop applications,Games applications as well as Browsers andWeb applications. "
                    },
                    {
                        "id": 3,
                        "name": "Python",
                        "ssUrlName": "Python",
                        "icon": "",
                        "Decription": "Python has become one of the most widespread programming languages in recent years. Moreover, Python is a general-purpose language which means it is created and used in Wide Range of Apps, Data Science, Software and Web development including programming and web development, Automation Software and generally CompleteProgram tasks."
                    },
                    {
                        "id": 4,
                        "name": "Java",
                        "ssUrlName": "Java",
                        "icon": "",
                        "Decription": "Java is a high-level programming language primarily used to develop Computer Applications. Java has many features which are useful in developing software and this language is the third most powerful language in the world."
                    },
                    {
                        "id": 5,
                        "name": "JavaScript",
                        "ssUrlName": "JavaScript",
                        "icon": "",
                        "Decription": "JavaScript is a programming language that was designed to make web-page authoring easier. It is used in many popular web browsers and it provides the interactive features you see on most websites."
                    },
                    {
                        "id": 6,
                        "name": "GO",
                        "ssUrlName": "GO",
                        "icon": "",
                        "Decription": " Go is an open source language developed by Google. Go language is HighlySecure and Very Fast. Go language developsWeb Servers or Operating System and is easy for developers to write less code and work fast."
                    },
                ]
            }
        ]
    }

    return (
        <SolutionPage solution={desktop} />
    )
}

export default DesktopApp