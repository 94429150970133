import React from 'react'
import { Card } from "react-bootstrap";

function Investors() {
  const investors = [
    {
      "name": "PATHI PS",
      "percent": "[Founder and CEO]"
    },
    {
      "name": "OS",
      "percent": "[Founder and CFO]"
    },
    {
      "name": "PADMINI RAJPUT ",
      "percent": "[COO]"
    },
    {
      "name": "UDAYAGIRI BHAVANA ",
      "percent": "[CTO]"
    },
    {
      "name": "SINDHU REDDY GANDHAM",
      "percent": "[CMO]"
    },
    {
      "name": "UDAYAGIRI RAMAKRISHNA",
      "percent": "[CIO]"
    },
    
    {
      "name": "DINESH GOWDA",
      "percent": "[GM]"
    },
    {
      "name": "SYED MAHIRA",
      "percent": ""
    },
    
    {
      "name": "BITTU RAMANA REDDY",
      "percent": ""
    },
  ]

  return (
    <div>
      <div class="card-get-in shadow-lg">
        <div class="card-body">
          <h5><img src={require("../../images/psos_tech_logo.jpg")} alt="psos_logo" className="about-logo" /></h5>
          <h5>Company Top Officials</h5>
          <Card.Body>
            <hr />
            <ul class="list-group gap-1">
              {investors.map((investor) => {
                return (
                  <div>
                    <li class="list-group-item d-flex justify-content-center align-items-center">
                      <p>{investor.name} - <b>{investor.percent}</b></p>
                    </li>
                  </div>
                )
              })}

            </ul>
          </Card.Body>
          <div className='poweredBy'>
            <img src={require("../../images/psos_tech_logo.jpg")} alt="psos_logo" className="about-logo" />
            <h5>Powered By PSOS Technologies</h5>
          </div>


        </div>
      </div>
      <br />
      <br />
    </div>
  )
}

export default Investors