import './App.css';
import React, { useState,createContext } from 'react';

import { Routes, Route } from "react-router-dom";
import { Container } from "react-bootstrap";

import { Provider } from "react-redux";
import store from "./store";

import NavbarPsos from './components/Navbar';
import Footer from './components/Footer';
import Home from './screens/Home';

import AboutUs from './screens/AboutUs_2';
import Dreams from './screens/AboutUs/Dreams';
import Ethics from './screens/AboutUs/Ethics';
import Investors from './screens/AboutUs/Investors';
import Promoters from './screens/AboutUs/Promoters';
import Story from './screens/AboutUs/Story';
import Vision from './screens/AboutUs/Vision';

import Register from "./screens/Auth/Register";
import Login from "./screens/Auth/Login";
import LogOut from "./screens/Auth/LogOut";
import ResetPassword from "./screens/Auth/ResetPassword";
import ChangePassword from "./screens/Auth/ChangePassword";

import Gallery from "./screens/Gallery copy";
import Create from "./screens/Gallery/Create";
import Update from "./screens/Gallery/Update";
import Delete from "./screens/Gallery/Delete";
import GPromoters from "./screens/Gallery/Promoters";

import Solution from './screens/SSolutions/Solution';
import OS from './screens/SSolutions/OS';
import CyberSecurity from './screens/SSolutions/CyberSecurity';
import CloudComputing from './screens/SSolutions/CloudComputing';
import MobileApps from './screens/SSolutions/MobileApps';
import DesktopApp from './screens/SSolutions/DesktopApp';
import WebApps from './screens/SSolutions/WebApps';
import Ai from './screens/SSolutions/Ai';
import Iot from './screens/SSolutions/Iot';
import Embedded from './screens/SSolutions/Embedded';
import GetInTouch from './components/GetInTouch';
import GetInTouchSolutions from './components/GetInTouchSolutions';

import Agriculture from './screens/Sectors/Agriculture';
import Travel from './screens/Sectors/Travel';
import RealEstate from './screens/Sectors/RealEstate';
import Accounting from './screens/Sectors/Accounting';
import Insurance from './screens/Sectors/Insurance';
import Automotive from './screens/Sectors/Automotive';
import Banking from './screens/Sectors/Banking';
import Charity from './screens/Sectors/Charity';
import EdTech from './screens/Sectors/EdTech';
import Energy from './screens/Sectors/Energy';
import Food from './screens/Sectors/Food';
import Health from './screens/Sectors/Health';
import Housing from './screens/Sectors/Housing';
import Manufacturing from './screens/Sectors/Manufacturing';
import Marine from './screens/Sectors/Marine';
import Media from './screens/Sectors/Media';
import Retail from './screens/Sectors/Retail';
import Sports from './screens/Sectors/Sports';
import Defence from './screens/Sectors/Defence';

// import { initialState, reducer } from "./reducer/UseReducer";
export const IsLoggedIn = createContext();
const initialState = 0;
const reducer = (state = initialState, action) => {
    if (action.type === "USER") {
        return action.payload;
    }
    return state;
};
  

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  return (
    <div className="App">
     
      <Container className="fluid">
      <Provider store={store}>
          <NavbarPsos />
          <div className="nav-space">
          </div>
          <Routes>

            <Route path='/' element={<Home />} />
            <Route path='/about' element={<AboutUs />} />
            <Route path='/about/ethics' element={<Ethics />} />
            <Route path='/about/story' element={<Story />} />
            <Route path='/about/vision' element={<Vision />} />
            <Route path='/about/investors' element={<Investors />} />
            <Route path='/about/promoters' element={<Promoters />} />
            <Route path='/about/dreams' element={<Dreams />} />
            <Route path="/register" element={<Register />} />
            <Route path="/login" element={<Login setIsLoggedIn={setIsLoggedIn} />} />
            <Route path="/logOut" element={<LogOut setIsLoggedIn={setIsLoggedIn} />} />
            <Route path="/resetpassword" element={<ResetPassword />} />
            <Route path="/changepassword/:tokenId/:userId" element={<ChangePassword />} />
            <Route path="/gallery" element={<Gallery />} />
            <Route path="/gallery/create" element={<Create />} />
            <Route path="/gallery/update" element={<Update />} />
            <Route path="/gallery/delete" element={<Delete />} />
            <Route path="/gallery/team" element={<GPromoters />} />

            <Route path="/solutions/os" element={<OS />} />
            <Route path="/solutions/cyber-security" element={<CyberSecurity />} />
            <Route path="/solutions/cloud" element={<CloudComputing />} />
            <Route path="/solutions/mobile" element={<MobileApps />} />
            <Route path="/solutions/web" element={<WebApps />} />
            <Route path="/solutions/desktop" element={<DesktopApp />} />
            <Route path="/solutions/ai" element={<Ai />} />
            <Route path="/solutions/iot" element={<Iot />} />
            <Route path="/solutions/embedded" element={<Embedded />} />

            <Route path="/sectors/agriculture" element={<Agriculture />} />
            <Route path="/sectors/travel" element={<Travel />} />
            <Route path="/sectors/realEstate" element={<RealEstate />} />
            <Route path="/sectors/accounting" element={<Accounting />} />
            <Route path="/sectors/automotive" element={<Automotive />} />
            <Route path="/sectors/banking" element={<Banking />} />
            <Route path="/sectors/charity" element={<Charity />} />
            <Route path="/sectors/energy" element={<Energy />} />
            <Route path="/sectors/food" element={<Food />} />
            <Route path="/sectors/health" element={<Health />} />
            <Route path="/sectors/housing" element={<Housing />} />
            <Route path="/sectors/insurance" element={<Insurance />} />
            <Route path="/sectors/manufacturing" element={<Manufacturing />} />
            <Route path="/sectors/marine" element={<Marine />} />
            <Route path="/sectors/media" element={<Media />} />
            <Route path="/sectors/sports" element={<Sports />} />
            <Route path="/sectors/edTech" element={<EdTech />} />
            <Route path="/sectors/retail" element={<Retail />} />
            <Route path="/sectors/defence" element={<Defence />} />

            <Route path="/solutions/:solutionId" element={<Solution />} />
            <Route path="/getintouch/:sector/:subsector" element={<GetInTouch />} />
            <Route path="/getintouchSol/:solution/:subSolution" element={<GetInTouchSolutions />} />
          </Routes>
        </Provider>
      </Container>
      <Footer />
        
      
     </div>
  );
}

export default App;

