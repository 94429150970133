import React from 'react'
import SectorPage from '../../components/SectorPage';

function EdTech() {

    const edTech = {
        "name": "EdTech & eLearning",
        "secUrlName": "EdTech-&-eLearning",
        "description": " With the growth of Institutes, Colleges, Schools - Advance Remote Learning had become actively growing in the recent days. Hence we would like to provide software the Educational Institutions need.We work with Schools, Colleges, Institutes and Universities to create Interactive Learning Environment which benefits both Educational Institutions and Students. It allows students to learn from anywhere.",
        "metaDesc": "EdTech and eLearning software and apps provide. Software operation to attestation. Analytics & Dash boarding Integrated Library System more software.",
        "name2": "EdTech Features & Sevices",
        "icon": "FaBookReader",
        "Services": [
            {
                "id": 1,
                "name": "Learning Management Systems (LMS)",
                "ssecUrlName": "Learning-Management-Systems-(LMS)",
                "icon": "",
                "Decription": "We help Educational Institutions to go fully digital with the Literacy Operation Systems. From Educational Content, Administration, Software Operation to Attestation, Course Design, Shadowing and Managing Training and Development programs, we help in making a completely Integrated Literacy Operation System to achieve asked Pretensions."
            },
            {
                "id": 2,
                "name": "Integrated Library System",
                "ssecUrlName": "Integrated-Library-System",
                "icon": "",
                "Decription": "We help Educational Institutions make Robust Library Operation Systems that automate the workflow in a library and grease complete resource optimization. We automate the complete process of visibility and traceability of books. With an advanced intertwined library operation system, preceptors can manage the library tasks and functions, right from listing to distribution."
            },
            {
                "id": 3,
                "name": "Online Proctoring Solutions",
                "ssecUrlName": "Online-Proctoring-Solutions",
                "icon": "",
                "Decription": "Our Al grounded online proctoring results enable education businesses to conduct examinations complete. We inoculate Al capabilities to cover suspicious conditioning and examinees geste while they take examinations ever and at their convenience, in some cases. We enables preceptors to disrupt the conventional style of scheduling and holding examinations."
            },
            {
                "id": 4,
                "name": "Analytics & Dashboarding",
                "ssecUrlName": "Analytics-Dashboarding",
                "icon": "",
                "Decription": "We help Education Service Providers to view the data through a single dashboard. We use state of the art tools institutes and colleges and mission learning tools, advance technology ml tools to collect and provide advance data to institutes andschools.We develop education analytics results to achieve business intelligence perceptivity and issues from data."
            },
            {
                "id": 5,
                "name": "Interactive Learning ",
                "ssecUrlName": "Interactive-Learning ",
                "icon": "",
                "Decription": "We help preceptors develop visually appealing educational content that successfully drives scholars' interest. With our interactive literacy services, we enhance their capabilities to deliver a more engaging, interactive, and substantiated literacy gests to scholars. We work to revamp the traditional models of education by adding game rudiments to them."
            },
            {
                "id": 6,
                "name": "On-Demand Learning Apps",
                "ssecUrlName": "On-Demand-Learning-Apps",
                "icon": "",
                "Decription": "Our experts help educational institutions design on- demand customized literacy apps that allow them to reach every individual pupil. The eLearning apps designed by our experts impeccably meet content, experts, preceptors, as well asscholars on one single platform and also enables preceptors to offer customized educational results."
            },

        ]


    }

    return (
        <SectorPage sector={edTech} />
    )
}

export default EdTech