import React from 'react'
import { Card } from "react-bootstrap";

function Story() {
  return (
      <div>
          <div class="card-get-in shadow-lg">
              <div class="card-body">
              <h5><img src={require("../../images/psos_tech_logo.jpg")} alt="psos_logo" className="about-logo"/></h5>
                <h5>Our Company Story</h5>
                
                <hr />
                <Card.Text style={{ color: "#1c0373" }}>
                The company PSOS Technologies born from the ideas of Pathi, Sindhu & OS.We wanted to start a company in 2013 but we didn't have money as we were studying as students. Sindhu died a few days later, we are starting this company with the thoughts and memories of that girl. Since 2015, we have started research and development of the company. We registered the company officially in 8/8/2022. That is the PSOS Technologies company memories.
                  </Card.Text>
                    <div className='poweredBy'>
                        <img src={require("../../images/psos_tech_logo.jpg")} alt="psos_logo" className="about-logo" />
                        <h5>Powered By PSOS Technologies</h5>
                    </div>
             </div>
          </div>
          <br />
          <br />
      </div>
  )
}

export default Story