import React from 'react'
import SectorPage from '../../components/SectorPage';

function Sports() {

  const sports = {
    "name": "Sports",
    "secUrlName": "Sports",
    "description": "Exponential growth in the count of Athletes and Sportsman also Increases the necessity of having Sensor Advance Applications they can use. This also indicates the need of Professionals(to go people), who are readily available helping the sportsmen with the needed skills .With the increase of Sport Leagues and Participation, this area is evolved huge and take a turn in unexplored avenues and innumerable openings.",
    "metaDesc":"Sports software and apps provide. Online Registration We can provide software for sports online payments with our registration software more software.",
    "name2": "Sports Features & Sevices",
    "icon":"FaVolleyballBall",
    "Services": [
        {
            "id": 1,
            "name": "Online Registration",
            "ssecUrlName": "Online-Registration",
            "icon":"",
            "Decription": "Weprovide software for the people interested in sports to register online without any paper work. As it usually is lengthy process which even is worrisome. Hence bringing it online and making it simple and confidential giving a wide range of opportunities to the ones in choosing what they are interested. "
        },
        {
            "id": 2,
            "name": "Payment Processing",
            "ssecUrlName": "Payment-Processing",
            "icon":"",
            "Decription": "Digitalizing the payments is an added benefit with our software as it is easy, safe and secure. We would also enable the reminders on payments which helps you not to bother on the dates of payment. We will provide all kinds of payment methods including  payment ensuring the processing transaction software is automatic and part of the registration"
        },
        {
            "id": 3,
            "name": "Team and Roster Creation",
            "ssecUrlName": "Team-and-Roster-Creation",
            "icon":"",
            "Decription": "We also provide an option in the software that automatically aligns the players and coaches as a team. You will also be given an option to provide your choice which will be checked while creating the teams and handing over the responsibilities. We provide online suggestions wrt locations and detailed information on the places you are looking for which helps you decide what you want."
        },
        {
            "id": 4,
            "name": "Events Scheduling",
            "ssecUrlName": "Events-Scheduling",
            "icon":"",
            "Decription": "Easily set your season game dates with a click using our simple Event Scheduling Software.  Enter your game time, courts, weeks and brigades to automatically trigger your schedule report. We also Update Parents and Players via SMS notifications in case of any changes in the schedule or cancellation."
        },
        {
            "id": 5,
            "name": "Advanced Custom Websites",
            "ssecUrlName": "Advanced-Custom-Websites",
            "icon":"",
            "Decription": "Get a custom website for your sports organization with advanced tools like online registration, player management, photo galleries as well as names, game schedules, scores and standings and plenty of widgets.  Once it's built, you'll be able to handle it completely on your own (no experience required) because we'll train you on the automated system and provide ongoing support."
        },
        {
            "id": 6,
            "name": "Online Courses",
            "ssecUrlName": "Online-Courses",
            "icon":"",
            "Decription": "We give platform for online courses which helps choosing career prospects based on the wide range on interests, by including subjects like Cricket History & Administration, Sports Marketing, Sports Psychology, Fitness & Nutrition, and numerous other detailed motifs; all of which will be presented to you by reputed experts and professionals."
        },
        
    ]
    
        
}

return (
    <SectorPage sector={sports} />
)
}

export default Sports