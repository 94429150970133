import React from 'react'

function LogOut() {

  // set configurations
  // const configuration = {
  //   method: "post",
  //   url: "http://localhost:5000/logout",
  //   data: {
  //     email,
  //     password,
  //   },
  // };

  // // make the API call
  // axios(configuration)
  //   .then((result) => {
  //     console.log(result);
  //     // set the cookie
  //     if (result.status !== 200) {
        
  //       window.alert(`${result.data.msg}`);
  //     } else {
  //       window.alert(`${result.data.user.email} Logged In  Successful ..!`);
  //       cookies.set("TOKEN", result.data.token, {
  //         path: "/",
  //       });
  //       // userAuthenticated();
  //       // redirect user to the auth page
  //       navigate('/', { replace: true });
  //       setIsLoggedIn(false);
  //       setLogin(true);

  //     }
      
  //   })
  //   .catch((error) => {
  //     console.log(error.response.data.msg);
  //     window.alert(`${error.response.data.msg}`);
  //   });



  return (
    <div>LogOut</div>
  )
}

export default LogOut;