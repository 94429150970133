import React from 'react'
import SectorPage from '../../components/SectorPage';


function Media() {

    const media = {
        "name": "Media and Entertainment",
        "secUrlName": "Media-and-Entertainment",
        "description": "Advanced Media and Entertainment resultsin Better Content Creation, Operation and Delivery. M&E houses can use similarresults to shift their content from traditional channels(e.g. review, print magazines, etc.) to digital- to stay applicable and engage further. We explore the top media and entertainment software results that help you deliver a substantiated client experience.",
        "metaDesc": "Media and entertainment software & apps provide Music Streaming Software Music streaming app. Mobile radio app development referral tracking software more.",
        "name2": "Media & Entertainment Services",
        "icon": "FaPlayCircle",
        "Services": [
            {
                "id": 1,
                "name": "Video Streaming Software",
                "ssecUrlName": "Video-Streaming-Software",
                "icon": "",
                "Decription": "We give features likemulti-camera operations, virtual sets, web aqueducts, etc. You can proceed  videotape streaming tasks more withour software. We help you broadcast events online that capture followership attention and increase engagement. Performing tasks like title edit, transitions, instant  renewal, overlays and live  videotape  goods come a  breath with these tools."
            },
            {
                "id": 2,
                "name": "Music Streaming Software",
                "ssecUrlName": "Music-Streaming-Software",
                "icon": "",
                "Decription": " Erecting a music streaming app like Spotify etc. is in high demand at the moment due to its rising fashionability among  guests. Music streaming mobile or web apps lets guests tune into their favorite playlists anytime, anywhere and via any Internet- enabled device. With a music streaming app, you get multiple monetization options in the form of paid subscriptions, advertisements and  further."
            },
            {
                "id": 3,
                "name": "Social Media Development",
                "ssecUrlName": "Social-Media-Development",
                "icon": "",
                "Decription": "We are also into developingsocial networking website or mobile app based on yourrequirements. Social media website development always results in the delivery of platforms with rich functionality, high performance, and a considerable degree of user satisfaction."
            },
            {
                "id": 4,
                "name": "Radio & Podcast Development",
                "ssecUrlName": "Radio-&-Podcast-Development",
                "icon": "",
                "Decription": "We design the user interface to make the audience feel very easy while using the app. We use referral tracking software to refer to the audio listening. This software is designed to suggest songs to the audience based on their requests."
            },
            {
                "id": 5,
                "name": "Content management Services",
                "ssecUrlName": "Content-management-Services",
                "icon": "",
                "Decription": "Whether you are a business proprietor, Marketer, Developer, or Commodity in between, we help you  manage the content in one click. Automates your content marketing and digital publishing. We're experts in top- rated tools like Adobe InDesign, Canvas, Dropbox, Google Drive, and Mail chimp to produce a flawless workflow for creating, storing, transubstantiating, and online publishing."
            },
            {
                "id": 6,
                "name": "Digital Audio Workstation",
                "ssecUrlName": "Digital-Audio-Workstation",
                "icon": "",
                "Decription": "A digital audio workstation(DAW) is a software that lets you turn your ideas snappily into live music. It eases out the complex music  product process- from recording to mixing, and everything in between. You can Record and Mix Music, Intuitive Dashboard, Edit Music and much  further with this tool."
            },
            {
                "id": 7,
                "name": "Mobile Game Development",
                "ssecUrlName": "Mobile-Game-Development",
                "icon": "",
                "Decription": "We can create everything from the creation of educational games for kids to fantasy sports software development. We can develop sports such as fantasy cricket, football, basketball, baseball, golf, hockey, soccer, auto racing, and cricket. We also add APIs to retrieve sports, league, and player information."
            }

        ]


    }

    return (
        <SectorPage sector={media} />
    )
}

export default Media