import React from 'react';
import SolutionPage from '../../components/SolutionPage';

function Iot() {
    const iot = {
        "name": "Internet of Things (IoT) Development",
        "sUrlName": "Internet-of-Things-(IoT)-Development",
        "description": "From Smart/Advance Cities Infrastructures to Standalone IoT devices, our team of knowledge and experienced people (who worked with the most innovative IoT platforms and SDK's) performing secure and fully scalable IoT Solutions. We design and develop IoT applications to wide variety of Operating Systems.",
        "metaDesc":"Internet of Things solutions and services provide smart city advance cities infrastructures to stand alone loT. Applications on iOS. Android and Windows more.",
        "catagories": [
            {
                "name": "Internet of Things (IoT) Sevices",
                "description": "",
                "services": [
                    {
                        "id": 1,
                        "name": "IoT Applications",
                        "ssUrlName": "IoT-Applications",
                        "icon": "",
                        "Decription": "We build Advance and Innovative Cross Platform IoT Applications in iOS, Android and Windows Operating Systems. We make your IoT app smarter with advance tools using AI and Machine Learning."
                    },
                    {
                        "id": 2,
                        "name": "Wearable IoT Apps",
                        "ssUrlName": "Wearable-IoT-Apps",
                        "icon": "",
                        "Decription": "We develop highly functional and user friendly wearable applications with Profile Management, Data Collection, Instant Interoperability, Advance Security, Advance Notifications and Other Innovative Functionalities and Features."
                    },
                    {
                        "id": 3,
                        "name": "Product Development",
                        "ssUrlName": "Product-Development",
                        "icon": "",
                        "Decription": "Many IoT based sectors such as Healthcare, Agriculture, Energy, Retail etc use advance IoT to monitor, control and optimize device performance. We build scalable smart connected IoT Systems through our IoT and Advance Embedded Development Services."
                    }
                ]
            }
        ]


    }

    return (
        <SolutionPage solution={iot} />
    )
}

export default Iot