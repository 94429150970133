import React from 'react';
import SolutionPage from '../../components/SolutionPage';

function Ai() {

    const ai = {
        "name": "AI & Machine learning",
        "sUrlName": "AI-&-machine-learning",
        "description": "Artificial Intelligence (AI) is the simulation of human intelligence processes by machines especially computer systems. These processes include learning (the acquisition of information and rules for using the information), reasoning (using rules to reach approximate or definite conclusions), and self-correction. Specific applications of Al include expert systems, natural language processing (NLP), speech recognition, and machine vision. Al is widely used in various fields such as healthcare, finance, roboticsand more, enabling systems to perform tasks that typically require human intelligence.",
        "metaDesc":"Powerful team in this field we analyze what kind of Ai tools we need in this and we make it accordingly. Advance Ai will rule in all fields in future Ai more",
        "catagories": [
            {
                "name": "AI & Machine learning Sevices",
                "description": "",
                "services": [
                    {
                        "id": 1,
                        "name": "Business Analyst",
                        "ssUrlName": "business-analyst",
                        "icon": "",
                        "Decription": "Business analysts (BAs) play a crucial role in bridging the gap between IT and business operations, helping organizations define and solve problems through data-driven insights and strategic planning. AI tools can significantly enhance the capabilities of business analysts by automating routine tasks, providing advanced data analysis, and offering predictive insights Forecasting AI can predict future trends based on historical data, helping businesses make proactive decisions."
                    },
                    {
                        "id": 2,
                        "name": "Data Extractor",
                        "ssUrlName": "data-extractor",
                        "icon": "",
                        "Decription": "Go beyond simple optical character recognition (OCR) by extracting to recognize the structure and text to analyze text such as recognizing characters in any handwritten document, editing text containing tables, etc."
                    },
                    {
                        "id": 3,
                        "name": "NLP",
                        "ssUrlName": "NLP",
                        "icon": "",
                        "Decription": "The main role is to analyze the data according to needs of customer having theirsentiments considered and getting closer to what they want. Getting your search engine to index key phrases and contacting your customers to automate their contracts, agreements, insights from similar information and court valuable documents."
                    },
                    {
                        "id": 4,
                        "name": "Build chatbots with AI",
                        "ssUrlName": "build-chatbots-with-AI",
                        "icon": "",
                        "Decription": "Build virtual agents and voice assistants, enable self-service capabilities in virtual contact center agents and interactive voice response attachment (IVR). Automating basic user tasks in your application with chatbots to provide accurate answers and to create conversational solutions that provide answers to frequently asked questions by customers."
                    },
                    {
                        "id": 5,
                        "name": "Speech to text",
                        "ssUrlName": "speech-to-text",
                        "icon": "",
                        "Decription": "Speech-to-text AI, also known as Automatic Speech Recognition (ASR) is a technology that transcribes spoken language into text. It involves converting spoken words from audio signals into written text, enabling machines to understand and process human speech. This is how it generally works."
                    },
                    {
                        "id": 6,
                        "name": "Computer vision",
                        "ssUrlName": "computer-vision",
                        "icon": "",
                        "Decription": "Computer vision is a field of artificial intelligence (AI) that focuses on enabling machines to interpret and understand visual information from the worldsimilar to the way the humans do. It involves acquiring, processing, analyzing and understanding images. In some cases it involves high-dimensional data from the real world to produce numerical or symbolic information in healthcare are the key aspects of computer vision."
                    },
                    {
                        "id": 7,
                        "name": "Industrial AI",
                        "ssUrlName": "industrial-ai",
                        "icon": "",
                        "Decription": "Industrial Al refers to the application of artificial intelligence technologies in industrial settings to improve efficiency, productivityand safety. Here are some key areas where industrial Al is making significant impacts."
                    },
                    {
                        "id": 8,
                        "name": "Healthcare",
                        "ssUrlName": "healthcare",
                        "icon": "",
                        "Decription": "Building an AI-powered chatbot for healthcare involves additional considerations as we understand the sensitivity of medical information and the need for accuracy and compliance with regulations such as HIPAA (Health Insurance Portability and Accountability Act)."
                    },
                    {
                        "id": 9,
                        "name": "Future AI",
                        "ssUrlName": "future-ai",
                        "icon": "",
                        "Decription": "Advance AI will rule in all fields like Automotives, Food, Medicine, Sports, Defense Sector and Manufacturing. AI will work ten times faster than human intelligence in many fields and it will expand all the options in all fields. We have a powerful team who works exclusively on this right fromanalyzing the kind of AI tools needed in respective field and make it accordingly."
                    },
                ]
            }
        ]
    }

    return (
        <SolutionPage solution={ai} />
    )
}

export default Ai