import React from 'react'
import SectorPage from '../../components/SectorPage';

function Retail() {

    const retail = {
        "name": "Retail/eCommerce App",
        "secUrlName": "Retail-or-eCommerce-App",
        "description": "Success-driven apps considering end-to-end process like Accurate Order Management, Dispatch Planning, Smart Rider Allocation, Dynamic and Scheduled Route Planning ensuring Faster reach to Customers all over.",
        "metaDesc":"Retail and e-commerce software and apps providing with accurate order management daily dispatch planning. Scheduled route planning more.",
        "name2": "Retail & Ecommerce - Features & Sevices",
        "icon": "FaStore",
        "Services": [
            {
                "id": 1,
                "name": "Ecommerce-App",
                "ssecUrlName": "Ecommerce-App",
                "icon": "",
                "Decription": "Our Best-in-class Support Platoon can help deliver your website and apps in relatively less number of days. Never worry yourself about Technology or Tech Knowledge involved as we help you get what you need. Be it a budding one or well established business, we make it easy for you creating a Brand Online that shines forever.  "
            },
            {
                "id": 2,
                "name": "Delivery-Management",
                "ssecUrlName": "Delivery-Management",
                "icon": "",
                "Decription": "We get you a platform that helps you manage multiple activities from Accepting Orders to Allocation, Route Optimization and Shadowing and Track and Trace every order placed in real-time. In simple terms, we manage the entire cycle of an order."
            },
            {
                "id": 3,
                "name": "Supply-Chain",
                "ssecUrlName": "Supply-Chain",
                "icon": "",
                "Decription": "We give you an end-to-end force chain visibility revealing how it reacts to different planning scripts. Test Contingency plans in real time to maintain healthy force situations and ameliorator experiences."
            },

        ]


    }

    return (
        <SectorPage sector={retail} />
    )
}
export default Retail;