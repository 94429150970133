import React from 'react'
import SectorPage from '../../components/SectorPage';


function Health() {

    const health = {
        "name": "Health & Fitness",
        "secUrlName": "Health-&-Fitness",
        "description": "Our platform helps you Manage Health &Fitness Services with Powerful Membership Management, Sales, Marketing and Reporting functions that give you the tools to be successful. We focus on Delivering an Engaging and Well Branded Member Experience for your Gym Members. Go with Paperless Onboarding for you and your staff.",
        "metaDesc":"Health and fitness software’s and apps provide fitness management software that can help you achieve your goals we provide Software training platform more.",
        "name2": "Health & Fitness Software Services",
        "icon": "FaHeartbeat",             
        "Services": [
            {
                "id": 1,
                "name": "Gym Management system",
                "ssecUrlName": "Gym-Management-system",
                "icon": "",
                "Decription": "Gym Management System is a Fitness Management Software that can help you set your business activities online and achieve your goals. Whether you're a Community Gym, A Fitness Studio, A Health Club, A State-of-the Art Multi Site Facilityor a PT FitnessProfessionals, can Create &Track Custom Training &Nutrition Programs for Clients, Manage Billing, Marketing, Automated Reminders and More. "
            },
            {
                "id": 2,
                "name": "Virtual Yoga Training",
                "ssecUrlName": "Virtual-Yoga-Training",
                "icon": "",
                "Decription": "Even if you are an individual looking for self help, we will be the right choice for you. Wedirectly provide you with the unique streaming virtual services that you need online help with Yoga/Gym/Workoutwithout any third party involvement. We also make the apps suitable for your Business as we use advance tools to build your business, guide your clients, your trainers, and give you the tools you need."


            },
            {
                "id": 3,
                "name": "Sports & Training Acadamy",
                "ssecUrlName": "Sports-&-Training-Acadamy",
                "icon": "",
                "Decription": "We provide Software Training Platform for Trainer’s to Coachthe Young Aspirants who want to join Indian Armed Forces (Army, Navy, and Air Force). We developcustomized online systems for sport associations, clubs, soccer schools and individual trainers."
            }


        ]


    }
    return (
        <div>
            <SectorPage sector={health} />
            <hr />
            <h2>How sofware helps to grow your Health & Fitness business?</h2>
            <hr />
            <p>A lot of business owners rely on pen and paper or simple spreadsheets to keep track. However, as they grow  membership management, payments, and class bookings can become messy and confusing, as a result  poor member experience can cause you to lose customers .Members need to feel like everything, from booking appointments to setting up recurring payments, is as hassle-free as possible.</p>
            <hr />
            <br />
        </div>
    )

}

export default Health;
