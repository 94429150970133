import React from 'react'

import * as Icons from "react-icons/fa";
import { IconContext } from "react-icons";

function IconComponent(props) {
    const IconComponent = Icons[props.icon];
    const color = props.color;
    const size = props.size;
    const icon = props.icon;
    return(
        <React.Fragment>
            <IconContext.Provider value={{ color: color, size: size }}>
                < IconComponent icon={icon} /> 
            </IconContext.Provider>
            {/* <IconContext.Provider value={{ color: color, size: size }}>
                <IconComponent/>
            </IconContext.Provider> */}
        </React.Fragment>
    );
}

export default IconComponent;
