import React from 'react'
import SolutionPage from '../../components/SolutionPage'

function CloudComputing() {
    const cloud = {
        "name": "Cloud Computing",
        "sUrlName": "Cloud-Computing",
        "description": "Cloud computing is an innovation in speed and security,providing multiple computing services including unlimited servers, storage, databases, networking, software, analytics and intelligence over the Internet. It furtherachieve flexible resources and economies of scale and advance speed. ",
        "metaDesc":"Cloud computing solutions provide Infrastructure as a Service laaS. Platform as a Service PaaS. Software as a Service SaaS. We provide using AWS, Azure, GCP and more.",
        "catagories": [
            {
                "name":"All different sevices in Cloud Computing are classified into 3",
                "description": "",
                "services": [
                    {
                        "id": 1,
                        "name": "Infrastructure as a Service (IaaS)",
                        "ssUrlName": "Infrastructure-as-a-Service-(IaaS)",
                        "icon": "",
                        "Decription": "Infrastructure as a Service (IaaS) is a part of cloud computing that provides network and storage. Cloud services can save money and it’s services include paying only for what we use. Shifting your organization's infrastructure to IaaS cloud computing can help your organization save money by freeing up your data centers and security issues."
                    },
                    {
                        "id": 2,
                        "name": "Platform as a Service (PaaS)",
                        "ssUrlName": "Platform-as-a-Service-(PaaS)",
                        "icon": "",
                        "Decription": "Platform as a Service (PaaS) is the next layer – the underlying storage, networking, software and virtual servers.This layer also includes the tools and software services that developers need to build forhandling applications such as middleware, database management. There are servers for developing operating systems"
                    },
                    {
                        "id": 3,
                        "name": "Software as a Service (SaaS)",
                        "ssUrlName": "Software-as-a-Service-(SaaS)",
                        "icon": "",
                        "Decription": "Software as a Service (SaaS) is a part of cloud computing where applications are delivered and quick response.This has probably the most used cloud computing speed. The underlying hardware, software and operating system are accessible to the end user through a web browser or app and is accepted by the customer."
                    }
                ]
            },
            {
                "name":"Most popular cloud providers which we are working",
                "description": "",
                "services": [
                    {
                        "id": 1,
                        "name": "Aws",
                        "ssUrlName": "Aws",
                        "icon": "",
                        "Decription": "Build, deployand manage websites, apps or processes on AWS secure reliable network.Our team have AWS cloud experts and provide secure, reliable and scalable services. These are easily manage clusters, highly scalable,durable, safe and secure."
                    },
                    {
                        "id": 2,
                        "name": "Azure",
                        "ssUrlName": "Azure",
                        "icon": "",
                        "Decription": "Microsoft Azure realize the advantage of open and flexible servers with cost savings and powerful cloud services. We have a team of experts who canmake you to affiliate more effective."
                    },
                    {
                        "id": 3,
                        "name": "GCP",
                        "ssUrlName": "GCP",
                        "icon": "",
                        "Decription": "Google Cloud Platform modernize your apps and services within your company's infrastructure. It offers highly reliable, high-performance and full security cloud services, mix &match services, run at google scale. Our team includes GCP experts who will help business 5o focus on the product."
                    },
                ]
            }
            
        ]
    }
    return (
        <SolutionPage solution={cloud} />
    )

}

export default CloudComputing