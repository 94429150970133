import React, { useState } from "react";
import { Form, Button,Card } from "react-bootstrap";
import axios from "axios";
import { Link } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
import PageHead from "../../components/PageHead";
import * as PSOSConstants from '../../components/PSOSConstants';

export default function Register() {
  // initial state
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [password, setPassword] = useState("");
  const [confPassword, setConfPassword] = useState("");
  const isEmail = (email) => /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email);
  const pagetitle = PSOSConstants.DOMAIN_NAME+"|Register";
  const metaDesc = "Registration with PSOS Technologies. Register here to get latest updates and offers from us.";
  const navigate = useNavigate();

  const handleSubmit = (e) => {
    if (name.trim().length < 3 || name.trim().length > 25) {
      return window.alert(`Name test should be not be less than 3 or greater than 25.`);
    }
    if (!isEmail(email)) {
      return window.alert(`Please entere valid email.`);
    }

    if (password !== confPassword) {
      return window.alert(`Passwords did not match`);
    }
    if (password.trim().length < 8) {
      return window.alert(`Passwords should not be below 8 characters.`);
    }
    // prevent the form from refreshing the whole page
    e.preventDefault();
    // set configurations
    const baseUrl =  process.env.REACT_APP_.BASE_URL ;
    const configuration = {
      method: "post",
      url: `${baseUrl}/register`,
      data: {
        name,
        email,
        password,
        confPassword,
      },
    };

    // make the API call
    axios(configuration)
      .then((result) => {
        if (result.status!==201) {
          return window.alert(`${result.data.msg}`);
        } else {
          window.alert(`${result.data.msg}`);
          navigate('/login', { replace: true });
        }
      })
      .catch((error) => {
        return window.alert(`Bad request! ${error.response.data.msg}`);
      });
  };

  return (
    <div >
      <PageHead title={pagetitle} description={metaDesc} />
      <div class="card-form shadow-lg col-sm-6 col-md-7 col-lg-5 mx-auto" >
            <div class="card-body ">
              <Card.Body>
            <h2>Register</h2>
            <hr />
              <Form onSubmit={(e) => handleSubmit(e)}>

                <Form.Group controlId="formBasicName">
                  <Form.Label>Name</Form.Label>
                  <Form.Control size="sm"
                    type="name"
                    name="name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    placeholder="Jhon"
                  />
                </Form.Group>
                <Form.Group controlId="formBasicEmail">
                  <Form.Label>Email address</Form.Label>
                  <Form.Control size="sm"
                    type="email"
                    name="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="example@gamil.com"
                  />
                </Form.Group>

                
                <Form.Group controlId="formBasicPassword">
                  <Form.Label>Password</Form.Label>
                  <Form.Control size="sm"
                    type="password"
                    name="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    placeholder="********"
                  />
                </Form.Group>
                <Form.Group controlId="formBasicConfPassword">
                  <Form.Label>Conform Password</Form.Label>
                  <Form.Control
                    type="password" size="sm"
                    name="confPassword"
                    value={confPassword}
                    onChange={(e) => setConfPassword(e.target.value)}
                    placeholder="********"
                  />
                </Form.Group>
                <br />
                <h5>
                  <Button className="primary"
                    variant="primary"
                    type="submit"
                    onClick={(e) => handleSubmit(e)}
                  >
                    <p>Register</p>
                  </Button>
                </h5>
                <h5>Already Register ? Login <Link to="/login">Here</Link></h5>
                
                </Form>
              </Card.Body>
            </div>
          </div>  
        
    </div>
  );
}
