import React from 'react';
import { FaFacebook, FaInstagram, FaYoutube,FaEnvelope } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { Link } from "react-router-dom";

function Footer() {
  const domainEmail = process.env.REACT_APP_.EMAIL;
  return (
    <div>

      <footer className="footer">
        <div className="container">
          <div className="cards">
            <div className="card-sector-foo">
              <h5>Company</h5>
              <ui>
                <li><Link to="/">Home</Link></li>
                <li><Link to="/about">AboutUs</Link></li>
                <li><Link to="/register">Register</Link></li>
                <li><Link to="/login">Login</Link></li>
                <li><Link to="/getintouchSol/Feel-free-connect-with-us/General-query-about">Connect with Us</Link></li>

              </ui>
              <br />
              <h5>Contact Us</h5>
              <ui>
                <li><a href={`mailto:${domainEmail}`}>E-mail</a></li>
                <li><a href={`mailto:${domainEmail}`}>{domainEmail}</a></li>
              </ui>

            </div>
            <div className="card-sector-foo">
              <h5>Software Solutions</h5>
              <ui>
                <li><Link to="/solutions/web">Web Apps</Link></li>
                <li><Link to="/solutions/mobile">Mobile Apps</Link></li>
                <li><Link to="/solutions/desktop">Desktop Apps</Link></li>
                <li><Link to="/solutions/ai">Ai & Machine learning</Link></li>
                <li><Link to="/solutions/embedded">Embedded Systems</Link></li>
                <li><Link to="/solutions/os">Research on new OS</Link></li>
                <li><Link to="/solutions/cyber-security">Cyber- Security</Link></li>
                <li><Link to="/solutions/cloud">Cloud computing</Link></li>
                <li><Link to="/solutions/iot">IoT</Link></li>
              </ui>
            </div>
            <div className="card-sector-foo">
              <h5>Software Services & Apps</h5>
              <ui>
                <li><Link to="/sectors/agriculture" state={{ icon: "FaSeedling" }}>Agriculture </Link></li>
                <li><Link to="/sectors/automotive" state={{ icon: "FaCar" }}>Automotive </Link></li>
                <li><Link to="/sectors/retail" state={{ icon: "FaStore" }}>Retail/eCommerce </Link></li>
                <li><Link to="/sectors/health" state={{ icon: "FaHeartbeat" }}>Health & Fitness </Link></li>
                <li><Link to="/sectors/banking" state={{ icon: "FaLandmark" }}>Banking & Finance </Link></li>
                <li><Link to="/sectors/edTech" state={{ icon: "FaBookReader" }}>EdTech & eLearning </Link></li>
                <li><Link to="/sectors/charity" state={{ icon: "FaHandHoldingHeart" }}>Charity & Not for Profit</Link></li>
                <li><Link to="/sectors/energy" state={{ icon: "FaHandHoldingWater" }}>Energy & Utilities</Link></li>
                <li><Link to="/sectors/accounting" state={{ icon: "FaRegAddressCard" }}>Accounting </Link></li>
              </ui>
            </div>
            <div className="card-sector-foo">
              <h5>Software Services & Apps</h5>
              <ui>
              <li><Link to="/sectors/sports" state={{ icon: "FaVolleyballBall" }}>Sports</Link></li>
                <li><Link to="/sectors/travel" state={{ icon: "FaGlobeAmericas" }}>Travel</Link></li>
                <li><Link to="/sectors/food" state={{ icon: "FaPeopleCarry" }}>Food Delivery</Link></li>
                <li><Link to="/sectors/manufacturing" state={{ icon: "FaIndustry" }}>Manufacturing</Link></li>
                <li><Link to="/sectors/housing" state={{ icon: "FaBuilding" }}>Housing & Construction</Link></li>
                <li><Link to="/sectors/media" state={{ icon: "FaPlayCircle" }}>Media & Entertainment</Link></li>
                <li><Link to="/sectors/realEstate" state={{ icon: "FaCity" }}>Real Estate & Property</Link></li>
                <li><Link to="/sectors/marine" state={{ icon: "FaWater" }}>Marine</Link></li>
                <li><Link to="/sectors/insurance" state={{ icon: "FaChild" }}>Insurance</Link></li>
                <li><Link to="/sectors/defence" state={{ icon: "FaChild" }}>Defence Software</Link></li>
              </ui>
            </div>
          </div>
        </div>
        <p className="copy-right">&copy; 2022 PSOS TECHNOLOGIES All rights reserved.</p>
        <ui>

          <li>
            <span><a href="https://twitter.com/PSOSTECHNOLOGIE" ><FaXTwitter /></a></span>
            <span><a href="https://www.instagram.com/psostechnologies/" ><FaInstagram /></a></span>
            <span><a href="https://www.facebook.com/PSOSTECHNOLOGIES1/" ><FaFacebook /></a></span>
            <span><a href="https://www.youtube.com/@PSOSTECHNOLOGIES" ><FaYoutube /></a></span>
            <span><a href={`mailto:${domainEmail}`}><FaEnvelope /></a></span>
          </li>

        </ui>
        <br />
      </footer>

    </div>
  )
}

export default Footer;

