import React from 'react';
import SolutionPage from '../../components/SolutionPage';

function Embedded() {

    const embedded = {
        "name": "Embedded Software Development",
        "sUrlName": "Embedded-Software-Development",
        "description": "Embedded Software refers to Computer Software that is programmed to perform a specific function within a hardware system. It is used in a wide variety of devices, from simple household appliances to complex systems such as automotive and aerospace systems.",
        "metaDesc":"Embedded software provide software computer programmed to perform simple household appliances to complex systems such as automotive and aerospace systems.",
        "catagories": [
            {
                "name": "Embedded Software Services",
                "description": "",
                "services": [

                    {
                        "id": 1,
                        "name": "Introduction of Embedded Software",
                        "ssUrlName": "Introduction-of-Embedded-Software",
                        "icon": "",
                        "Decription": "The software is embedded within the hardware, which means it is stored and executed within the device and it typically operates in real-time with little or no human intervention. The software is designed to be highly reliable, efficient and often operates under resource-constrained environments."
                    },
                    {
                        "id": 2,
                        "name": "Life cycle of Embedded Software",
                        "ssUrlName": "Life-cycle-of-Embedded-Software",
                        "icon": "",
                        "Decription": "This typically involves a Range of Processes including Requirements Analysis, Design, Coding, Testingand Maintenance. This process is usually carried out by a Team of Specialized Engineers who are familiar with Hardware and Software Technologies used in Specific Device or System."
                    },
                    {
                        "id": 3,
                        "name": "Languages in Embedded Software",
                        "ssUrlName": "Languages-in-Embedded-Software",
                        "icon": "",
                        "Decription": "Some common programming languages used in this development includes C, C++ and Assembly language. The software is typically developed using specialized development tools and testing frameworks that are tailored to the specific device or system being developed."
                    },
                    {
                        "id": 4,
                        "name": "Modern devices",
                        "ssUrlName": "Modern-devices",
                        "icon": "",
                        "Decription": "Embedded software is critical in the functioning of many modern devices and its development is becoming increasingly important as more devices become connected and intelligent."
                    }

                ]

            }
        ]
    }

    return (
        <SolutionPage solution={embedded} />
    )

}

export default Embedded