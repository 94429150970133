import React, { useState } from 'react';
import { Nav, Navbar } from "react-bootstrap";
import { Link } from "react-router-dom";
import Cookies from "universal-cookie";

import { useDispatch, useSelector } from "react-redux";
import { REMOVE_CURRENT_USER } from "../actions/types";

function Psos_Navbar_() {

  const dispatch = useDispatch();
  const isLoggedIn = useSelector(state => state.auth.isAuthenticated);
  // const  user = auth.user;
  const [expanded, setExpanded] = useState(false);

  const setCurrentUser = decoded => {
    return {
      type: REMOVE_CURRENT_USER,
      payload: decoded
    };
  };

  const handleSubmit = (e) => {
    // prevent the form from refreshing the whole page
    e.preventDefault();
    const cookies = new Cookies();
    cookies.remove("TOKEN");
    dispatch(setCurrentUser({}));

  }


  return (
    <div className="shadow-lg fixed-top">
      <Navbar expanded={expanded} bg="white" sticky="top" expand="md">

        <Navbar.Brand as={Link} to="/">
          <img src={require('../images/psos_tech_logo.jpg')} width="70" height="50" alt="psos_logo" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" onClick={() => setExpanded(!expanded)} />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className='me-auto fw-bold'>
            <Nav.Link as={Link} to="/" onClick={() => setExpanded(false)}>Home</Nav.Link>
            <Nav.Link as={Link} to="/about" onClick={() => setExpanded(false)}>AboutUs</Nav.Link>
            <Nav.Link as={Link} to="/gallery" onClick={() => setExpanded(false)}>Gallery</Nav.Link>

          </Nav>
          <Nav className='fw-bold'>
            {isLoggedIn ? (
              <React.Fragment>
                <Nav.Link as={Link} to="/" onClick={() => setExpanded(false)}><span onClick={(e) => handleSubmit(e)}>Logout</span></Nav.Link>
              </React.Fragment>
            )
              :
              (<React.Fragment>
                <Nav.Link as={Link} to="/register" onClick={() => setExpanded(false)}>Register</Nav.Link>
                <Nav.Link as={Link} to="/login" onClick={() => setExpanded(false)}>Login</Nav.Link>
              </React.Fragment>
              )
            }
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </div>
  )

}

export default Psos_Navbar_;
