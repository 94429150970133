import React from 'react'
import SectorPage from '../../components/SectorPage';

function Accounting() {

    const accounting = {
        "name": "Accounting",
        "secUrlName": "Accounting",
        "description": " Accounting software is a tool that helps businesses track and manage their finances,allows company owners record transactions, download financial statements and reports, manage customer information and payments, create and send invoices, share reports and financial information with an accountant, file business taxes etc.",
        "metaDesc": "Accounting software and apps provide accounts software is a tool that helps businesses track and you can enter in all of your transactions to track each.",
        "name2": "Accounting Features & Sevices",
        "icon": "FaRegAddressCard",
        "Services": [
            {
                "id": 1,
                "name": "Record transactions",
                "ssecUrlName": "Record-transactions",
                "icon": "",
                "Decription": "Transactions in accounting plays crucial role. With our software, you can enter all your transactions to track each purchase, expense, revenueand more.Along with that we provide individual anddepartment wise transactions to check on how much spent."
            },
            {
                "id": 2,
                "name": "Financial statements and reports",
                "ssecUrlName": "Financial-statements-and-reports",
                "icon": "",
                "Decription": "There are numerous software solutions designed to help with the preparation, analysis and management of financial statements and reports. These tools range from basic to highly sophisticated, catering to the needs of small businesses, large corporations and everything in between."
            },
            {
                "id": 3,
                "name": "Manage customers",
                "ssecUrlName": "Manage-customers",
                "icon": "",
                "Decription": "When you build a customer base, it's important to keep track of various bills and outstanding invoices. With our accounting software, you can see various Information about your customers (e.g.. past due balances)."
            },
            {
                "id": 4,
                "name": "Create and send invoices",
                "ssecUrlName": "Create-and-send-invoices",
                "icon": "",
                "Decription": "Creating and sending invoice,  software typically involves features like customizable invoice templates, client management, invoice tracking and payment reminders. We can help youhaving any specific requirements or preferences in this software ."
            },
            {
                "id": 5,
                "name": "Account Collaboration",
                "ssecUrlName": "Account-Collaboration",
                "icon": "",
                "Decription": "Account collaboration software typically refers to tools that enable teams to work together on managing and organizing account-related tasks, contacts and information. Examples include Salesforce, HubSpot, and Zoho CRM which facilitate collaboration among sales, marketing and customer service teams to streamline customer relationship management processes."
            },
            {
                "id": 6,
                "name": "Automated pre-filled tax",
                "ssecUrlName": "Automated-pre-filled-tax",
                "icon": "",
                "Decription": "Automated pre-filled tax software is designed to streamline the tax filing process by automatically populating certain fields with relevant information, such as income, deductions, and credits, based on data provided by employers, financial institutions, and government agencies. It can save time and reduce errors for taxpayers, making it easier to file accurately and efficiently."
            },

        ]


    }
    return (
        <SectorPage sector={accounting} />
    )

}

export default Accounting