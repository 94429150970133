import React from 'react'
import SectorPage from '../../components/SectorPage';

function Energy() {

    const energy = {
        "name": "Energy & Utilities",
        "secUrlName": "Energy-Utilities",
        "description": "We're offering comprehensive IT results that aim to contemporize business operations by lessening homemade work, automating processes, and offering near real-time information in the smart data analytics, asked format, at the asked time. fastening on varied areas like means and force, field services, safety and compliance, operations and more.",
        "metaDesc": "Energy and Utilities software apps provide management software solutions. Software and transition modules to focus on individual work areas and more.",
        "name2": "Energy Features & Sevices",
        "icon": "FaHandHoldingWater",
        "Services": [
            {
                "id": 1,
                "name": "ERP for Energy & Utilities",
                "ssecUrlName": "ERP-for-Energy-and-Utilities",
                "icon": "",
                "Decription": "Enterprise resource planning (ERP) a blend of business software solutions, meant for the energy & utility industry, that emphasize resource planning, management & operational control between diverse departments or sections or offices or locations.Integrated software modules through software and transition modules to focus on individual work areas."
            },
            {
                "id": 2,
                "name": "CRM for Energy & Utilities ",
                "ssecUrlName": "CRM-for-Energy-&-Utilities",
                "icon": "",
                "Decription": "Custom CRM (Customer relationship management) development for energy & utilities highlighting the merger of features under each important area - Sales, Service, Marketing & Satisfaction.Fully customizable to fit industry-specific needs, it provides primary, secondary to sales and tertiary sales workflows."
            },
            {
                "id": 3,
                "name": "Billing System",
                "ssecUrlName": "Billing-System",
                "icon": "",
                "Decription": "We're creating platforms for managing electricity/ gas/ power consumption, force, analysis, soothsaying, billing/ invoicing for the energy request. A revolutionary result for an effective metering and billing system. Facilitates its consumers with advanced cash inflow, increased profit, accurate cadence readings, centralized integration, increased translucency, and client satisfaction"
            },

        ]


    }
    return (
        <SectorPage sector={energy} />
    )

}

export default Energy;