import React from 'react';
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";



function Sectors() {

    const sectors = [
        {
            "id": 1,
            "name": "Housing & Construction",
            "description": "Housing and construction are vital sectors that significantly impact economies and communities. Here’s an overview of key aspects related to both We provide softwares related to housing and construction",
            "image": "FaBuilding",
            "url": "housing",
            "image1": "Housing & Construction.webp"  
        },
    
        {
            "id": 2,
            "name": "Agriculture",
            "description": "Digital agriculture, often referred to as precision agriculture or smart farming, involves the use of modern technologies and data analytics to improve agricultural practices and efficiency. Here are some key aspects and technologies involved in digital agriculture",
            "image": "FaSeedling",
            "url": "agriculture",
            "image1": "Agriculture.webp"
            
        },
        {
            "id": 3,
            "name": "Real Estate & Property",
            "description": "Real estate and property management software are essential tools for professionals in the real estate industry, offering various functionalities to streamline operations, manage properties effectively, and enhance customer  experiences  we  providing  softwares",
            "image": "FaCity",
            "url": "realEstate",
            "image1": "Real Estate & Property.webp"
        },
        {
            "id": 4,
            "name": "Accounting ",
            "description": "There are various accounting software solutions that aim to automate processes, increase productivity, enhance security, predict consumer behaviorand ultimately provide a better service to customers. Some of the notable ones include Software.",
            "image": "FaRegAddressCard",
            "url": "accounting",
            "image1": "Accounting.webp"
        },
        {
            "id": 5,
            "name": "Banking & Finance",
            "description": "Banking and finance apps are crucial for managing personal finances, banking transactions, investments, and budgeting. They offer convenience, securityand accessibilityallowing users to check account balances, transfer funds, pay bills and even invest in stocks or cryptocurrencies from their smartphones.",
            "image": "FaLandmark",
            "url": "banking",
            "image1": "Banking & Finance.webp",
        },
        {
            "id": 6,
            "name": "Charity & Not for Profit",
            "description": "Charities are constantly looking for ways to raise funds and sympathizers. We work with non-profit organizations to help provide secure support for mobile operations and compatible software.",
            "image": "FaHandHoldingHeart",
            "url": "charity",
            "image1": "Charity & Not For Profit.webp",
            
        },
        {
            "id": 7,
            "name": "Travel",
            "description": "Traveling software encompasses a wide range of applications designed to enhance and streamline the travel experience. These tools can assist with booking, planning, we providing many travelling softwares and apps",
            "image": "FaGlobeAmericas",
            "url": "travel",
            "image1": "Travel.webp",
            
        },
        {
            "id": 8,
            "name": "Food Delivery",
            "description": "Food and delivery apps and software have become integral parts of modern life, offering convenience and a wide range of options for consumers contact food delivery apps and softwares providing",
            "image": "FaPeopleCarry",
            "url": "food",
            "image1": "Food Delivery.webp",
        },
        {
            "id": 9,
            "name": "Automotive",
            "description": "Automotive software refers to computer programs and systems used in vehicles to manage various functions, ranging from engine control and diagnostics to entertainment and navigation systems  we  providing all type automotives softwares",
            "image": "FaCar",
            "url": "automotive",
            "image1": "Automotive.webp",
            
        },
        {
            "id": 10,
            "name": "Retail / Ecommerce",
            "description": "We provide the promising apps that enhances B2C operations in Retail Industry. The real motive behind isproviding lucrative opportunity to the Business by enhancing the customer engagement with the caterers. These apps results in Win-Win Situation for both the Business(own app makes more reliable) and Customers(every need in one click).",
            "image": "FaStore",
            "url": "retail",
            "image1": "Retail & Ecommerce.webp"
            
        },
        {
            "id": 11,
            "name": "Insurance",
            "description": "As everyone aware of the immeasurable and highly difficult tasks are to be handled by every Insurance Agencies and the Brokerage Establishments, we are planning to help them with a software to accommodate and align their line of work.",
            "image": "FaChild",
            "url": "insurance",
            "image1": "Insurance.webp",
            
        },
        {
            "id": 12,
            "name": "Manufacturing",
            "description": "Using Advancemanufacturing tools, We provide software that make robots work without humans. We help manufacturing companies make software to their force chain conditions and manufacturing companies build software that fits their supply chain.",
            "image": "FaIndustry",
            "url": "manufacturing",
            "image1": "Manufacturing.webp",
            
        },
    
        {
            "id": 13,
            "name": "Health & Fitness",
            "description": "Utilizing loT and Other Software Technologies, we work with Private Medical Interpreters, Gymnasiums and Fitness Providers to Elevate Health and Fitness Shadowing, Remote Case Monitoring, Particular Training, Sanitarium Asset Shadowing and further.",
            "image": "FaHeartbeat",
            "url": "health",
            "image1": "Health & Fitness.webp",
        },
        {
            "id": 14,
            "name": "Marine",
            "description": "Marine software encompasses a wide range of applications and tools designed to support various activities and operations in the maritime industry. Here are some key categories and examples of marine software",
            "image": "FaWater",
            "url": "marine",
            "image1": "Marine.webp",
            
        },
    
        {
            "id": 15,
            "name": "Energy & Utilities",
            "description": "With the renewable energy transition on the rise, now is the best time to join the green movement.  We work with energy providers and utility companies to create management software solutions using advanced innovative tools and smart IoT technologies.",
            "image": "FaHandHoldingWater",
            "url": "energy",
            "image1": "Energy & Utilities.webp",
        },
        {
            "id": 16,
            "name": "Media & Entertainment",
            "description": "Who says NO to Entertainment. Though we have huge number of Entertainment channels out there like Prime, Netflix, Spotify (to name a few). If you are looking for one your own channel that beats the other Competitive channels, you are at right place. Yes, we help you articulate your thoughts and design your Own Application using Advance Software and Mission Learning Solutions.",
            "image": "FaPlayCircle",
            "url": "media",
            "image1": "Media & Entertainment.webp",
            
        },
        {
            "id": 17,
            "name": "EdTech & ELearning",
            "description": "Educational Technology (EdTech) and eLearning software are essential tools in modern education. They encompass a wide range of platforms and applications designed to facilitate learning and teaching processes. education software providing any type",
            "image": "FaBookReader",
            "url": "edTech",
            "image1": "EdTech & Elearning.webp",
            
        },
        {
            "id": 18,
            "name": "Sports",
            "description": "Considering the rapid growth in the interest in Sports, we being active enthusiasts like every others towards this field will work with Sports People Academies to provide applications that tailor the needs of Players with much consideration of safety for them.",
            "image": "FaVolleyballBall",
            "url": "sports",
            "image1": "Sports.webp",
        },
        {
            "id": 19,
            "name": "Defence Software",
            "description": "Autonomous Stealth Drones software, Defence autonomous vehicles softwares, missiles defence software,  Ai defence softwares,  Surveillance tracking software and  Cybersecurity softwares  We can provide this type of software only to countries",
            "image": "FaVolleyballBall",
            "url": "defence",
            "image1": "Defence.jpeg",
        }
    
    
    ]

    return (
        <div id='sectorCopyContainer'>
            <hr />
            <h2 className="customized-h2">CUSTOMIZED SOLUTIONS FOR YOUR SECTOR</h2>
            <div className="cards row gx-0">
                {sectors.map((sector) => {
                    return <div className="card-sector-two shadow-lg col-sm-9 col-md-5 col-lg-3"  key={sector.id}>
                        <Link to={`/sectors/${sector.url}`} state={{ icon: sector.image }} className="more">
                            <div class="card-body">
                                <img className="sectorImage" src={`../images/sector/${sector.image1}`} alt={`${sector.image1}`} />
                                <div className="sector-heading"><h2>{sector.name}</h2></div>
                                <p>{sector.description}</p>
                                <br />
                                <br />
                            </div>
                            <Button type="submit" className="more-sector"><p>More &rarr;</p></Button>
                        </Link>
                    </div>
                    
                })}
            </div>
        </div>

    )
}

export default Sectors;
