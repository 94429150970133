import React from 'react'
import SectorPage from '../../components/SectorPage';

function Defence() {
    const defence = {
        "name": "Defence Software",
        "secUrlName": "Defence",
        "description": "However, we would like to highlight that the services or the software we develop is discrete and this knowledge would never be shared to anyone which includes the people themselves who are involved in developing the same. We ensure that every single detail regarding this will be confidential and never be discussed anywhere. This Software is exclusively meant for Indian Defense Sector but will not be for Individuals/Company(s).",
        "metaDesc": "Defense software developed by PSOS TECHNOLOGIES company is not sold to persons or companies but softwares are developed in defense sector very confidentially these softwares are developed for national defense.",
        "name2": "We are not providing denfense software to any individuals or any private companies.",
        "icon": "",
        "Services": [
            {
                "id": 1,
                "ssecUrlName": "Defence",
                "icon": "",
                "Decription": "Defense Software developed by PSOS TECHNOLOGIES is not sold for Individuals or Companies but will be developed and designed as it is required for our Nation.",
                "name": "Defence Software",
            }
            
        ]
    }

    return (
        <SectorPage sector={defence} />
    )
}

export default Defence;