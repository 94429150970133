import React from "react";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import IconComponent from "../components/IconComponent";
import PageHead from "../components/PageHead";
import * as PSOSConstants from '../components/PSOSConstants';

function AboutUs() {

  const about_images = [
    {
      "path": "Story.PNG",
      "title": "Company Story",
      "url": "story",
      "icon": "FaBook",
      "q1": "How was the company founded?",
      "q2": "What is the company story?"
    },
    {
      "path": "Vision2.PNG",
      "title": "Company Vision",
      "url": "vision",
      "icon": "FaEye",
      "q1": "What is the future of the company and what is it going to do in the future?",
      "q2": "What is the company vision?"

    },
    {
      "path": "Ethics.PNG",
      "title": "Company Ethics",
      "url": "ethics",
      "icon": "FaBalanceScale",
      "q1": "What are Company Ethics?",
      "q2": "What kind of ethics does the company follow?"
    },
    {
      "path": "Dream.PNG",
      "title": "Company Dream",
      "url": "dreams",
      "icon": "FaMoon",
      "q1": "What kind of dreams does the company have?",
      "q2": "What is company dream?"
    },
    {
      "path": "Investors.PNG",
      "title": "Company top officials",
      "url": "investors",
      "icon": "FaCheckCircle",
      "q1": "Who are the top officials of the company?",
      "q2": "Does the company run by a top official team?"
    },
    {
      "path": "Promoters1.PNG",
      "title": "Company business groups",
      "url": "promoters",
      "icon": "FaUsers",
      "q1": "What are Company Business Groups?",
      "q2": "What type of business groups?"
    },
  ]
  
  
  const pagetitle = PSOSConstants.DOMAIN_NAME+"|About Us";
  const metaDesc = "PSOS Technologies, all details company story, company ethics, company vision, company dream, company higher official’s, company business groups and more.";
  return (
    <div className="about-page">
      <PageHead title={pagetitle} description={metaDesc} />
      <br />
      <h5><img src={require('../images/psos_tech_logo.jpg')} alt="psos_logo" className="about-logo"/></h5>
      <h5>PSOS Technologies Company details</h5>
      <hr />
      <div className="cards">
        {about_images.map((image) => {
          return <div className="card-sector shadow-lg col-sm-9 col-md-7 col-lg-3">
            <Link to={`/about/${image.url}`} className="more">
              <div class="card-body-about">
                <h5>
                  <div className="about-icon">
                    < IconComponent icon={image.icon} color="orange" size="5rem" />
                  </div>
                </h5>
                <h5>{image.title}</h5>
                <p><hr /></p>
                <p>{image.q1}</p>
                <p>{image.q2}</p>
                <br />
                <br />

              </div>

              <Button type="submit" className="more-sector"><p>more</p></Button>
            </Link>
          </div>
        })}
      </div>
    </div>
  )
}

export default AboutUs;
