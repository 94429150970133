import React ,{ useState } from 'react'
import axios from 'axios';
import {  Button,Card } from "react-bootstrap";

function Create() {
    const [file, setFile] = useState();
    const [fileName, setFileName] = useState("");

    const saveFile = (e) => {
        setFile(e.target.files[0]);
        setFileName(e.target.files[0].name);
    };

    const uploadFile = async (e) => {
        const formData = new FormData();
        formData.append("file", file);
        formData.append("fileName", fileName);
        console.log(formData);
        try {
            const res = await axios.post(
            "http://localhost:3000/upload",
            formData
            );
            console.log(res);
        } catch (ex) {
            console.log(ex);
        }
    };
    return (
        <div class="card-form shadow-lg col-sm-6 col-md-7 col-lg-5 mx-auto" >
            <div class="card-body ">
                <h5>Upload file to Gallery</h5>
                <hr />
            <Card.Body>
                <input type="file" onChange={saveFile} />
                    <br />
                    <br />   
                <Button className="primary"
                    variant="primary"
                    type="submit"
                    onClick={uploadFile}
                >
                    <p>Upload</p>
                </Button>
            </Card.Body>
            </div>
        </div>
    )
}

export default Create