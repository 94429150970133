import React, { useState } from "react";
import { Form, Button, Card } from "react-bootstrap";
import { useNavigate } from 'react-router-dom';
import * as PSOSConstants from '../../components/PSOSConstants';
import axios from "axios";

function ResetPassword() {
  // initial state
  const [email, setEmail] = useState("");
  const navigate = useNavigate();
  const isEmail = (email) => /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email);

  document.title = PSOSConstants.DOMAIN_NAME+"|Reset Password";
  const handleSubmit = (e) => {
    // prevent the form from refreshing the whole page
    e.preventDefault();
    if (!isEmail(email)) {
      return window.alert(`Please entere valid email.`);
    }
    const baseUrl = process.env.REACT_APP_.BASE_URL;
    // set configurations
    const configuration = {
      method: "post",
      url: `${baseUrl}/requestResetPassword`,
      data: {
        email
      },
    };

    // make the API call
    axios(configuration)
      .then((result) => {
        // redirect user to the auth page
        if (result.status !== 200) {
          window.alert(`${result.data.msg}`);
        } else {
          // window.alert(`Link sent to your ${email}.Please check and reset Password.`);
          window.alert(`${result.data.msg}`);
          // redirect user to the auth page
          navigate('/', { replace: true });
        }

      })
      .catch((error) => {
        window.alert(`${error.response.data.msg}`);
      });
  };

  return (
    <div>
      <div class="card-form shadow-lg col-sm-6 col-md-7 col-lg-5 mx-auto" >
        <div class="card-body ">
          <Card.Body>
            <h2>Reset Password</h2>
            <hr />
            <Form onSubmit={(e) => handleSubmit(e)}>
              {/* email */}
              <Form.Group controlId="formBasicEmail">
                <Form.Label>Enter your account Email address</Form.Label>
                <Form.Control size="sm"
                  type="email"
                  name="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="example@gmail.com"
                />
              </Form.Group>
              <br />
              <h5>
                <Button className="primary"
                  variant="primary"
                  type="submit"
                  onClick={(e) => handleSubmit(e)}
                >
                  <p>Submit to reset</p>
                </Button>
              </h5>
            </Form>
          </Card.Body>
        </div>
      </div>
      <br />
      <br />
      <br />
      <br />
    </div>
  )
}

export default ResetPassword