import React from 'react'
import SectorPage from '../../components/SectorPage';


function Charity() {
    const charity = {
        "name": "Charity & Not for Profit",
        "secUrlName": "Charity-Not-for-Profit",
        "description": "Charities are constantly looking for ways to connect with supporters and fundraiser volunteers.  To save money and engage key people and audiences.  We volunteer charity members work with non-profit organizations to help secure funding for mobile applications charity audio video software and custom software.",
        "metaDesc":"Charity and Not for Profit Software and apps Services mobile applications charity audio video software and Charitable custom software provide more.",
        "name2": "Charity & Not for Profit SoftWare Services",
        "icon": "FaHandHoldingHeart",
        "Services": [
            {
                "id": 1,
                "name": "Online Fund Raiser",
                "ssecUrlName": "Online-Fund-Raiser",
                "icon": "",
                "Decription": "Online Fund Raiser is the solution that provides a single source solutions for all your management needs, with nonprofit fund accounting, fund raising donor management and payroll in a single system.All of the various management responsibilities that are essential to running a successful nonprofit organization, reporting, fund raising, program management, payroll management, and government compliance."
            },
            {
                "id": 2,
                "name": "NGOs and foundations",
                "ssecUrlName": "NGOs-and-foundations",
                "icon": "",
                "Decription": "Especially in thenon-profit sector you want to make sure to maximise your coffers. We offer you a design operation system with translucency, inflexibility and security. This allows you to effectively manage your systems. Companies likeNon-governmental associations, Foundations, Associations, Charitable associations, andNon-profit associations save a lot of time by using our software."
            },
            {
                "id": 3,
                "name": "Nonprofits Organisation",
                "ssecUrlName": "Nonprofits-Organisation",
                "icon": "",
                "Decription": "Organisations comprise of churches, communal leagues, private foundations, public charities and further. Every organisation retain an area of functional enterprises. These are about ways of assembling coffers and distributing them to everything that comes in between the process. For relationship operation, utmost partake the demand of both amulti-location result and CRM software"
            }
        ]

    }
    return (
        <SectorPage sector={charity} />
    )

}

export default Charity