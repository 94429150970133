import React from 'react'
import Carousel from 'react-bootstrap/Carousel';


function LatestNews() {
  const latestNews = [
    {
      "id": 1,
      "title": "PSOS Technologies story",
      "description": "The company PSOS Technologies born from the ideas of Pathi, Sindhu & OS.We wanted to start a company in 2013 but we didn't have money as we were studying as students. Sindhu died a few days later, we are starting this company with the thoughts and memories of that girl. Since 2015, we have started research and development of the company. We registered the company officially in 8/8/2022. That is the PSOS Technologies company memories",
      "imagepath": "vision_1.webp"
    },
    {
      "id": 2,
      "title": "Our Vision",
      "description": "Our company's future is to expand in all sectors, for example from salt to pulses to pulses to mercury. Our dream is to have our DNA in every business.",
      "imagepath": "vision.jfif"
    },
    {
      "id": 3,
      "title": "We are working on technologies that will change the world",
      "description": "Artificial intelligence, Autonomous Operating System (OS), Innovative defence sector softwares, New all in one OS",
      "imagepath": "technology.jfif"
    },
  ]

  return (
    <div >
      <hr />
      <div className="carousel-div">
        <Carousel>
          {latestNews.map((news) => {
            return <Carousel.Item width="50%" height="50%" interval={1000} key={news.id}>
              <div className="carousel-body">
                <h5>{news.title}</h5>
                <p>{news.description}</p>
              </div>
            </Carousel.Item>
          })}
        </Carousel>
      </div>
    </div>
  )
}

export default LatestNews;

