import React from 'react'
import SectorPage from '../../components/SectorPage';


function Travel() {


    const travel = {
        "name": "Travel",
        "secUrlName": "Travel",
        "description": "We aim to provide apps and software that gives an easy access for the companies that mainly are into Travel Business. A software that suits different modes of transport (could be a Flights, Trains, Buses, Own or Rented Vehicles - Cars in specific). Software that helps with Services like Ticket Booking, Itinerary and Cancellation.",
        "metaDesc": "Travelling software’s and apps provide software for companies or individuals any kind of software such as buses cars flights trains taxis more.",
        "name2": "Travel Features & Sevices",
        "icon": "FaGlobeAmericas",
        "Services": [
            {
                "id": 1,
                "name": "Travel Agency",
                "ssecUrlName": "Travel-Agency",
                "icon": "",
                "Decription": "We help Travel Agencies to build a Software or an App with the specifications of respective businesses that greatly support them in Automating and Managing different Services like Booking, Inventory and Generating Reports. To simplify above all, weprovide exemplifying support to automate your business."
            },
            {
                "id": 2,
                "name": "Corporate Travel Managers",
                "ssecUrlName": "Corporate-Travel-Managers",
                "icon": "",
                "Decription": "What would be better than giving a hassle-free travel experience to your customers and that exactly is something we assure you for. As it is just not a simple travel we think,it would be the ever lasting memory for peoplewith unspoken emotions. Automating the general work like Booking, Tracking etc would matters and alsohelps them analyse the cost incurred through out."
            },
            {
                "id": 3,
                "name": "Tour Recommendation System",
                "ssecUrlName": "Tour-Recommendation-System",
                "icon": "",
                "Decription": "We also are introducing Tour Recommendation System,a process that suggests the Places andPackages toCustomers based on their Preferences, Budget, and theirTravel history. We are providing such system for Users to Explore the World to their Best (giventheir choicesthe first preference always). (PSOS Technologies Private Limited are ambitious in providingBudget-Friendly Travel Software/Apps for Businesses/Individuals)."
            },

        ]

    }
    return (
        <SectorPage sector={travel} />
    )
}

export default Travel