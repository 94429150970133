import React from 'react'
import { Card } from "react-bootstrap";

function Dreams() {
  return (
    <div>
      <div class="card-get-in shadow-lg">
        <div class="card-body">
          <h5><img src={require("../../images/psos_tech_logo.jpg")} alt="psos_logo" className="about-logo" /></h5> 
          <h5>Our Company Dream</h5>
          <hr />
          <Card.Text style={{ color: "#1c0373" }}>
            PSOS Technologies dream is to grow into the world's largest software company and create software for everyone.  Providing software required in all sectors. Our company's dream is to design the software that every common man in the world needs.
          </Card.Text>

          <div className='poweredBy'>
            <img src={require("../../images/psos_tech_logo.jpg")} alt="psos_logo" className="about-logo" />
            <h5>Powered By PSOS Technologies</h5>
          </div>
        </div>
      </div>
      <br />
      <br />
      <br />

    </div>
  )
}

export default Dreams