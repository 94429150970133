import React from 'react'
import SolutionPage from '../../components/SolutionPage';

function WebApps() {
    const webApps = {
        "name": "Web Apps",
        "sUrlName": "Web-Apps",
        "description": "Irrespective of you being an Individual or a Company, we help you establishing your Business by creating a Website. A page which allows you to project your products with the help of Internet anywhere. We help you grow your Brand from being an Individual or a Company. Being every thing on the Internet already, we must ensure our presence online as it brings us near to Customers and make things easy for them. We provide you a User Friendly, Qualitative and Simple website that not only gives a Professional layout. They will be easily navigated without loading issues during heavy traffic(when multiple people are using it at the same time).",
        "metaDesc":"Web apps, we provide frontend services using React js, Angular js, Vue js and backend as service using Java Script, Java ,C# & .Net, Python, php, Dart. provide apps and websites more",
        "catagories": [
            {
                "name": "Our Tech Stack in Frontend as Service",
                "description": "",
                "services": [
                    {
                        "id": 1,
                        "name": "React js",
                        "ssUrlName": "React-js",
                        "icon": "",
                        "Decription": "We have a dedicated team to design and develop native iOS Apps for all your iOS gadgets like iPhone, iPad, Apple Watches, Apple TV's, iPad, MAC etc. SEO Friendly –To make it big on the Internet, the best way is being Strong in SEO game  Migration to React Native (Easy for Mobile Apps) Easy for Migration (Migrating Cross Platforms for Mobile, Watches,  Cars etc) and to Update Existing Functionality."
                    },
                    {
                        "id": 2,
                        "name": "Angular js",
                        "ssUrlName": "Angular-js",
                        "icon": "",
                        "Decription": "As we all rely on Google, we bring it here because we want to inform you that Angular is a product from Google, hence it is assured for the Quality of Code and High-End Security. The simple architecture makes things easier and helps to build app(s) quickly without cumbersome code, thus making the website light. Large Scale and Scalable Apps."
                    },
                    {
                        "id": 3,
                        "name": "Vue js",
                        "ssUrlName": "Vue-js",
                        "icon": "",
                        "Decription": "Size and Simplicity (tiny framework of only 21KB)  Vue performs better than Angular and as well as React in manipulating rows and columns in tables Entry into market (best suitable) is easy and it is light weight "
                    }
                ]
            },
            {
                "name": "Our Tech Stack in Backend as Service",
                "description": "We provide Backend-as-a-Service, helpin providing web and mobile app developers with pre-written software API’s.Businesses require BaaS services such asAdmin Authentication, API Integration, CDN (Content Delivery Network), Cloud Storage & Backup, Database Management, Email Verification,Geographic location, Push-Notifications, Regulatory Compliance, Social Integration",
                "services": [
                    {
                        "id": 1,
                        "name": "Java Script",
                        "ssUrlName": "Java-Script",
                        "icon": "",
                        "Decription": "Anything that runs in a browser (and beyond) uses Java Script. This is the Only language that uses in browser "
                    },
                    {
                        "id": 2,
                        "name": "Java",
                        "ssUrlName": "Java",
                        "icon": "",
                        "Decription": "Enterprise (Banks, eCommerce, Google, etc.) & Legacy usage. Popular for Android apps."
                    },
                    {
                        "id": 3,
                        "name": "C# & .Net",
                        "ssUrlName": "C#-&-.Net",
                        "icon": "",
                        "Decription": "Windows apps, large websites (Unity games) prefers this. Especially used by Enterprises (Microsoft, healthcare, etc). Unique for easily querying and updating data."
                    },
                    {
                        "id": 3,
                        "name": "Python",
                        "ssUrlName": "Python",
                        "icon": "",
                        "Decription":"One of most demand language and in trend recently. Used for Math Scripts,Statistics in Websites. Especially used for Academics, Startups and Google. Unique for Data Science&AI. "
                    },
                    {
                        "id": 4,
                        "name": "Php",
                        "ssUrlName": "Php",
                        "icon": "",
                        "Decription": "Applications built on older scripts like WordPress. Especially used by Older Companies and Facebook. Unique for Quick Server Set Up and Web Host.  "
                    },
                    {
                        "id": 4,
                        "name": "Dart",
                        "ssUrlName": "Dart",
                        "icon": "",
                        "Decription": "Dart is targeting for cross platform mobile app development using flutter. Dart Web is used for web development. We can also use Dart for desktop apps."
                    }
                ]
            }
        ]
    

}

return (
    <SolutionPage solution={webApps} />
)
}

export default WebApps