import React, { useState } from "react";
import { Form, Button, Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
import jwt_decode from "jwt-decode";
import axios from "axios";
import Cookies from "universal-cookie";
import PageHead from "../../components/PageHead";

import { useDispatch } from "react-redux";
import { SET_CURRENT_USER } from "../../actions/types";
import * as PSOSConstants from '../../components/PSOSConstants';

const cookies = new Cookies();

export default function Login({ setIsLoggedIn }) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [login, setLogin] = useState(false);
  const isEmail = (email) => /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email);
  const pagetitle = PSOSConstants.DOMAIN_NAME+"|Login";
  const metaDesc = "Login with PSOS Technologies. Login here to get latest updates and offers from us.";
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // Set logged in user
  const setCurrentUser = decoded => {
    return {
      type: SET_CURRENT_USER,
      payload: decoded
    };
  };

  const handleSubmit = (e) => {
    // prevent the form from refreshing the whole page
    e.preventDefault();
    if (password.length < 8) {
      return window.alert("Passwords should not be below 8 characters.");
    }
    if (!isEmail(email)) {
      return window.alert(`Please entere valid email.`);
    }
    const baseUrl = process.env.REACT_APP_.BASE_URL;
    // set configurations
    const configuration = {
      method: "post",
      url: `${baseUrl}/login`,
      data: {
        email,
        password,
      },
    };

    // make the API call
    axios(configuration)
      .then((result) => {
        // set the cookie
        if (result.status !== 200) {

          window.alert(`${result.data.msg}`);
        } else {
          const decoded = jwt_decode(result.data.token);
          console.log(decoded);
          window.alert(`${decoded.email} Logged In  Successful ..!`);
          cookies.set("TOKEN", result.data.token, {
            path: "/",
          });
          // Set current user
          dispatch(setCurrentUser(decoded));
          // userAuthenticated();
          // redirect user to the auth page
          navigate('/', { replace: true });
          setIsLoggedIn(true);
          setLogin(true);

        }

      })
      .catch((error) => {
        console.log(error.response.data.msg);
        window.alert(`${error.response.data.msg}`);
      });
  };

  return (
    <div >
      <PageHead title={pagetitle} description={metaDesc} />
      <div class="card-form shadow-lg col-sm-7 col-md-7 col-lg-5 mx-auto" >
        <div class="card-body ">
          <Card.Body>
            <h2>Login</h2>
            <hr />
            <Form onSubmit={(e) => handleSubmit(e)}>
              {/* email */}
              <Form.Group controlId="formBasicEmail">
                <Form.Label>Email address</Form.Label>
                <Form.Control size="sm"
                  type="email"
                  name="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="example@gmail.com"
                />
              </Form.Group>

              {/* password */}
              <Form.Group controlId="formBasicPassword">
                <Form.Label>Password</Form.Label>
                <Form.Control size="sm"
                  type="password"
                  name="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  placeholder="********"
                />
              </Form.Group>

              <br />
              <h5>
                <Button className="primary"
                  variant="primary"
                  type="submit"
                  onClick={(e) => handleSubmit(e)}
                >
                  <p>Login</p>
                </Button>
              </h5>
              <h5>Forgot password? Reset <Link to="/resetpassword">Here</Link></h5>
              <h5>Don't have an account? Create <Link to="/register">Here</Link></h5>
            </Form>
          </Card.Body>
        </div>
      </div>

    </div>
  );
}
