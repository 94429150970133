import React from 'react';
import { Card,Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import PageHead from "./PageHead";
import * as PSOSConstants from '../components/PSOSConstants';


function SectorPage(props) {
    const { sector } = props;
    const pagetitle = PSOSConstants.DOMAIN_NAME+"|" + sector.name;
    // const sectorName = sector.name.replaceAll(" ", "-");
    
    
    return (<div className="sector-page" >
        <PageHead title={pagetitle} description={sector.metaDesc} />
        <h1>{sector.name}</h1>
        <hr />
        <p className="sector-description">{sector.description}</p>
        <hr />
        <h2>{sector.name2}</h2>
        <hr />
        <div className="cards">
            {sector.Services.map((service) => {
                // const serviceName = service.name.replaceAll(" ", "-");
                return (<div className="card-sector shadow-lg col-sm-9 col-lg-3 ">
                    <div class="card-body">
                        <h2>{service.name}</h2>
                        <hr />
                        <Card.Body>
                            <p>{service.Decription}</p>
                            <br />
                            <br />
                            <Link to={`/getintouch/${sector.secUrlName}/${service.ssecUrlName}`}>
                                <Button type="submit" className="more-sector"><p>Connect With Us</p></Button>
                            </Link>
                        </Card.Body>
                    </div>
                </div>)
            })

            }
        </div>

    </div>
    )
}

export default SectorPage;